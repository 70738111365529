import React, { useState, useEffect } from 'react';
import { WifiOff, RefreshCcw } from 'lucide-react';

interface ConnectionErrorProps {
  onRetry?: () => void;
}

export function ConnectionError({ onRetry }: ConnectionErrorProps) {
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const MAX_RETRIES = 5;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    if (isRetrying) {
      timeoutId = setTimeout(() => {
        onRetry?.();
        setIsRetrying(false);
      }, 2000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isRetrying, onRetry]);

  const handleRetry = () => {
    if (retryCount >= MAX_RETRIES) {
      return;
    }
    
    setRetryCount(prev => prev + 1);
    setIsRetrying(true);
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-gray-900 rounded-xl border border-gray-800 p-6 text-center">
        <div className="bg-red-900/20 rounded-full p-4 w-16 h-16 mx-auto mb-6 flex items-center justify-center">
          <WifiOff className="h-8 w-8 text-red-500" />
        </div>
        
        <h1 className="text-xl font-bold text-white mb-2">Connection Error</h1>
        <p className="text-gray-400 mb-6">
          Unable to connect to the server. Please check your internet connection and try again.
        </p>

        <button
          onClick={handleRetry}
          disabled={isRetrying || retryCount >= MAX_RETRIES}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isRetrying ? (
            <>
              <RefreshCcw className="h-4 w-4 animate-spin" />
              <span>Retrying...</span>
            </>
          ) : (
            <>
              <RefreshCcw className="h-4 w-4" />
              <span>Retry Connection</span>
            </>
          )}
        </button>

        {retryCount > 0 && (
          <p className="mt-4 text-sm text-gray-500">
            Retry attempt {retryCount} of 5
            {retryCount >= 5 && (
              <span className="block mt-2 text-gray-400">
                If the problem persists, please try again later or contact support.
              </span>
            )}
          </p>
        )}
      </div>
    </div>
  );
}