import { QuotaConfig } from './types';

export const DEFAULT_QUOTA_CONFIG: QuotaConfig = {
  limits: {
    dailyRequests: 55000
  },
  costs: {
    channelFetch: 500,
    videoFetch: 1000,
    commentFetch: 500,
    analyticsReport: 1500,
    aiSummary: 1000,
    aiChannelAnalysis: 2000,
    shortsAnalysis: 1500
  }
};