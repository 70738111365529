import React from 'react';
import { QuotaStatus } from '../quota/QuotaStatus';
import { useAuth } from '../../hooks/useAuth';

interface NavbarQuotaProps {
  className?: string;
}

export const NavbarQuota: React.FC = () => {
  const { user } = useAuth();

  // Only show quota status for authenticated users
  if (!user || !user.uid) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <QuotaStatus />
    </div>
  );
};