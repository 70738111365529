import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChange } from '../../lib/firebase';
import { PageService } from '../../lib/pages';
import { Loader2, AlertCircle } from 'lucide-react';
import { channelCache } from '../../lib/cache/channelCache';
import { AIAnalysis } from './AIAnalysis';
import { ShortsAnalysis } from '../shorts/ShortsAnalysis';
import { ChannelVideos } from './ChannelVideos';
import { DashboardHeader } from './DashboardHeader';
import { Navbar } from '../navigation/Navbar';
import type { User } from 'firebase/auth';
import type { YouTubeChannelDetails, YouTubeVideo } from '../../types/youtube';
import { getChannelDetails } from '../../lib/youtube';
import { SelectedVideoView } from './SelectedVideoView';

export function CreatorDashboard() {
  const navigate = useNavigate();
  const { customUrl } = useParams();
  const [channelDetails, setChannelDetails] = useState<YouTubeChannelDetails | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<YouTubeVideo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [quotaError, setQuotaError] = useState<{message: string; resetTime?: Date} | null>(null);

  const loadChannelData = useCallback(async (page: any) => {
    try {
      if (page?.channelDetails) {
        const cachedData = channelCache.get(page.channelDetails.id);
        if (cachedData) {
          setChannelDetails(cachedData);

          if (channelCache.shouldRefresh(page.channelDetails.id)) {
            refreshChannelData(page.channelDetails.id);
          }
        } else {
          const freshData = await getChannelDetails(page.channelDetails.id);
          setChannelDetails(freshData);
          channelCache.set(page.channelDetails.id, freshData);
        }
      }
    } catch (error) {
      console.error('Error loading channel data:', error);
      setError('Failed to load channel data');
    }
  }, []);

  const refreshChannelData = useCallback(async (channelId: string) => {
    try {
      setIsRefreshing(true);
      console.debug('CreatorDashboard: Refreshing channel data', { channelId });
      
      const freshData = await getChannelDetails(channelId);
      
      console.debug('CreatorDashboard: Fresh data received', {
        totalVideos: freshData.statistics.videoCount,
        loadedVideos: freshData.videos?.length || 0,
        shorts: freshData.videos?.filter(v => v.isShort).length || 0
      });
      
      setChannelDetails(freshData);
      channelCache.set(channelId, freshData);
      channelCache.updateRefreshTime(channelId);
    } catch (error: any) {
      if (error && typeof error === 'object' && 'code' in error && error.code === 'QUOTA_EXCEEDED') {
        setQuotaError({
          message: 'Daily API quota limit reached',
          resetTime: error.resetTime as Date
        });
      } else {
        console.error('Error refreshing channel data:', error);
      }
    } finally {
      setIsRefreshing(false);
    }
  }, []);

  useEffect(() => {
    const loadPageData = async (user: User | null) => {
      console.debug('CreatorDashboard: loadPageData called', { user, customUrl });
      setIsLoading(true);
      setError(null);
      
      if (!customUrl) {
        console.debug('CreatorDashboard: No customUrl provided');
        setError('Invalid URL');
        setIsLoading(false);
        return;
      }
      
      try {
        const page = await PageService.getPageByUrl(customUrl);
        console.debug('CreatorDashboard: Page data loaded', { page });

        if (!page || !user) {
          console.debug('CreatorDashboard: Page not found or user not authenticated', { page, user });
          setError('Page not found or not authenticated');
          navigate('/');
          return;
        }
        
        if (page.userId !== user.uid) {
          console.debug('CreatorDashboard: Unauthorized access', { pageUserId: page.userId, userUid: user.uid });
          setError('Unauthorized access');
          navigate('/');
          return;
        }
        
        await loadChannelData(page);
      } catch (error) {
        console.error('CreatorDashboard: Error loading page:', error);
        setError('Failed to load page data. Please try again later.');
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    };

    const unsubscribe = onAuthStateChange((user) => {
      console.debug('CreatorDashboard: Auth state changed', { user });
      loadPageData(user);
    });

    return () => {
      console.debug('CreatorDashboard: Cleaning up auth subscription');
      unsubscribe();
    };
  }, [navigate, customUrl, loadChannelData]);

  useEffect(() => {
    // Set a default selected video after channelDetails are loaded
    if (channelDetails?.videos?.length && !selectedVideo) {
      setSelectedVideo(channelDetails.videos[0]);
    }
  }, [channelDetails, selectedVideo]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="flex items-center gap-3">
          <Loader2 className="h-6 w-6 animate-spin text-purple-500" />
          <span className="text-gray-400">Loading your channel...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen bg-black text-white">
      <Navbar />
      {isRefreshing && (
        <div className="fixed top-4 right-4 bg-gray-800 text-gray-300 px-4 py-2 rounded-lg border border-gray-700 flex items-center gap-2">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span className="text-sm">Refreshing data...</span>
        </div>
      )}
      <main className="relative z-10 pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
          {channelDetails && (
            <DashboardHeader channelDetails={channelDetails} />
          )}

          <div className="relative bg-gradient-to-br from-gray-900 to-black rounded-xl overflow-hidden">
            <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1492619375914-88005aa9e8fb?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80')] opacity-5 bg-cover bg-center" />
            <div className="relative p-4 sm:p-8">
              {quotaError ? (
                <div className="bg-red-900/20 border border-red-800/30 rounded-lg p-6">
                  <div className="flex items-start gap-3">
                    <AlertCircle className="h-5 w-5 text-red-400 shrink-0 mt-0.5" />
                    <div className="space-y-2">
                      <p className="text-red-400 font-medium">{quotaError.message}</p>
                      <p className="text-sm text-gray-400">
                        Quotas reset at {quotaError.resetTime?.toLocaleTimeString()} UTC. 
                        Please try again later or upgrade your plan for higher limits.
                      </p>
                      <p className="text-sm text-gray-400">
                        You can still analyze Shorts content which uses a separate quota.
                      </p>
                    </div>
                  </div>
                  {channelDetails && (
                    <div className="mt-6">
                      <ShortsAnalysis channelDetails={channelDetails} />
                    </div>
                  )}
                </div>
              ) : error ? (
                <div className="bg-red-900/20 border border-red-800/30 text-red-400 px-4 sm:px-6 py-4 rounded-lg">
                  <div className="flex items-start gap-3">
                    <AlertCircle className="h-5 w-5 shrink-0 mt-0.5" />
                    <p>{error}</p>
                  </div>
                </div>
              ) : (
                <div className="mt-8">
                  {channelDetails && selectedVideo && (
                    <>
                      <SelectedVideoView video={selectedVideo} channelDetails={channelDetails} />
                      <ChannelVideos
                        channelDetails={channelDetails}
                        onSelectVideo={(video) => setSelectedVideo(video)}
                      />
                      <AIAnalysis channelDetails={channelDetails} />
                      <ShortsAnalysis channelDetails={channelDetails} />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}