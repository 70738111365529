import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  setDoc,
  serverTimestamp,
  FieldValue
} from 'firebase/firestore';
import { db } from './firebase';
import type { YouTubeChannelDetails } from '../types/youtube';

interface RegisteredPage {
  id: string;
  channelId: string;
  customUrl: string;
  channelDetails: YouTubeChannelDetails;
  createdAt: FieldValue;
  lastUpdated: FieldValue;
  userId: string;
}

const PAGES_COLLECTION = 'pages';
const URL_PATTERN = /^[a-zA-Z0-9-_]{3,30}$/;

export class PageService {
  /**
   * Validates a custom URL format
   */
  static validateCustomUrl(url: string): boolean {
    return URL_PATTERN.test(url);
  }

  /**
   * Checks if a custom URL is available
   */
  static async isUrlAvailable(customUrl: string): Promise<boolean> {
    const pagesRef = collection(db, PAGES_COLLECTION);
    const q = query(pagesRef, where('customUrl', '==', customUrl));
    const snapshot = await getDocs(q);
    return snapshot.empty;
  }

  /**
   * Reserves a custom URL for a channel
   */
  static async reservePage(
    customUrl: string,
    channelId: string,
    channelDetails: any,
    userId: string
  ): Promise<RegisteredPage> {
    // Validate inputs
    if (!customUrl || !channelId || !channelDetails || !userId) {
      throw new Error('Missing required parameters for page reservation');
    }

    // Validate URL format
    if (!this.validateCustomUrl(customUrl)) {
      throw new Error(
        'Invalid URL format. Use 3-30 characters, letters, numbers, hyphens, or underscores only.'
      );
    }

    // Check URL availability
    const isAvailable = await this.isUrlAvailable(customUrl);
    if (!isAvailable) {
      throw new Error('This URL is already taken. Please choose another one.');
    }

    // Check if user already has a page for this channel
    const existingPage = await this.getPageByChannelId(channelId);
    if (existingPage) {
      throw new Error('You already have a page for this YouTube channel');
    }

    // Create the page document
    const pagesRef = collection(db, PAGES_COLLECTION);
    const pageData: RegisteredPage = {
      id: doc(pagesRef).id,
      channelId,
      customUrl,
      channelDetails: {
        id: channelDetails.id,
        snippet: channelDetails.snippet,
        statistics: channelDetails.statistics
      },
      createdAt: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      userId
    };

    try {
      const docRef = doc(pagesRef, pageData.id);
      await setDoc(docRef, pageData);

      // Create rate limit document
      const rateLimitRef = doc(db, 'ratelimits', userId);
      await setDoc(rateLimitRef, {
        timestamp: serverTimestamp()
      });

      return pageData;
    } catch (error) {
      console.error('Error reserving page:', error);
      throw new Error('Failed to reserve page. Please check your permissions and try again.');
    }
  }

  /**
   * Gets a page by its custom URL
   */
  static async getPageByUrl(customUrl: string): Promise<RegisteredPage | null> {
    const pagesRef = collection(db, PAGES_COLLECTION);
    const q = query(pagesRef, where('customUrl', '==', customUrl));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return null;
    }

    return snapshot.docs[0].data() as RegisteredPage;
  }

  /**
   * Gets a page by channel ID
   */
  static async getPageByChannelId(channelId: string): Promise<RegisteredPage | null> {
    const pagesRef = collection(db, PAGES_COLLECTION);
    const q = query(pagesRef, where('channelId', '==', channelId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return null;
    }

    return snapshot.docs[0].data() as RegisteredPage;
  }

  /**
   * Gets all pages owned by a user
   */
  static async getPagesByUserId(userId: string): Promise<RegisteredPage[]> {
    const pagesRef = collection(db, PAGES_COLLECTION);
    const q = query(pagesRef, where('userId', '==', userId));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => doc.data() as RegisteredPage);
  }
}