import React, { useState, useCallback, memo } from 'react';
import { LogOut, User as UserIcon, ChevronDown } from 'lucide-react';
import { auth } from '../../lib/firebase';
import { useNavigate } from 'react-router-dom';

interface UserMenuProps {
  user: {
    displayName: string | null;
    email: string | null;
    photoURL: string | null;
  };
}

export const UserMenu = memo(function UserMenu({ user }: UserMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = useCallback(async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, [navigate]);

  const toggleMenu = useCallback(() => setIsOpen(prev => !prev), []);

  return (
    <div className="relative">
      <button
        onClick={toggleMenu}
        className="w-full flex items-center gap-2 px-3 py-2 hover:bg-gray-800 rounded-lg transition-colors"
      >
        <div className="relative">
          {user.photoURL ? (
            <>
              <img
                src={user.photoURL}
                loading="lazy"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                  target.parentElement?.querySelector('.fallback-icon')?.classList.remove('hidden');
                }}
                alt={user.displayName || 'User'}
                className="w-8 h-8 rounded-full border border-purple-500"
              />
              <div className="w-8 h-8 rounded-full bg-purple-600 flex items-center justify-center fallback-icon hidden absolute inset-0">
                <UserIcon className="h-4 w-4 text-white" />
              </div>
            </>
          ) : (
            <div className="w-8 h-8 rounded-full bg-purple-600 flex items-center justify-center">
              <UserIcon className="h-4 w-4 text-white" />
            </div>
          )}
        </div>
        <div className="hidden sm:block text-left">
          <p className="text-sm font-medium text-white">
            {user.displayName || 'User'}
          </p>
          <p className="text-xs text-gray-400">
            {user.email}
          </p>
        </div>
        <ChevronDown className="h-4 w-4 text-gray-400" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-gray-900 rounded-lg border border-gray-800 shadow-xl z-50">
          <button
            onClick={handleSignOut}
            className="w-full flex items-center gap-2 px-4 py-2 text-gray-300 hover:bg-gray-800 rounded-lg transition-colors"
          >
            <LogOut className="h-4 w-4" />
            <span>Sign out</span>
          </button>
        </div>
      )}
    </div>
  );
});