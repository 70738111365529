// src/lib/youtube.ts

import axios from 'axios';
import { withQuota } from './quota/middleware';
import type { YouTubeChannelDetails, YouTubeVideo, YouTubeComment } from '../types/youtube';
import { auth } from './firebase';

interface VideoFetchOptions {
  includeShorts?: boolean;
  maxResults?: number;
  pageToken?: string;
}

const YOUTUBE_API_KEY = import.meta.env.VITE_YOUTUBE_API_KEY;
const YOUTUBE_API_BASE = 'https://www.googleapis.com/youtube/v3';

export class ChannelNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ChannelNotFoundError';
    Object.setPrototypeOf(this, ChannelNotFoundError.prototype);
  }
}

export class InvalidHandleError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidHandleError';
    Object.setPrototypeOf(this, InvalidHandleError.prototype);
  }
}

export class YouTubeAPIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'YouTubeAPIError';
    Object.setPrototypeOf(this, YouTubeAPIError.prototype);
  }
}

/**
 * Retrieve channel details including snippet and statistics. If found, also fetch videos.
 */
export async function getChannelDetails(channelId: string): Promise<YouTubeChannelDetails> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User must be authenticated to fetch channel details');
  }

  if (!YOUTUBE_API_KEY) {
    console.error('YouTube API Error: Missing API key');
    throw new YouTubeAPIError('Unable to access YouTube API. Please check your configuration.');
  }

  try {
    const result = await withQuota(user, 'channelFetch', async () => {
      const params: Record<string, any> = {
        part: 'snippet,statistics',
        key: YOUTUBE_API_KEY,
      };

      if (channelId.startsWith('@')) {
        const handle = channelId.substring(1);
        if (!/^[a-zA-Z0-9_-]{3,30}$/.test(handle)) {
          throw new InvalidHandleError('Channel handle can only contain letters, numbers, underscores, and hyphens.');
        }
        params['forHandle'] = channelId;
      } else {
        if (!/^UC[a-zA-Z0-9_-]{22}$/.test(channelId)) {
          throw new ChannelNotFoundError('Invalid channel ID format. Channel IDs should start with "UC" followed by 22 characters.');
        }
        params['id'] = channelId;
      }

      const response = await axios.get(`${YOUTUBE_API_BASE}/channels`, { params });

    if (!response.data.items?.length) {
      const errorMessage = channelId.startsWith('@')
        ? `Channel "${channelId}" not found. Please check the handle and try again.`
        : `Channel ID "${channelId}" not found. Please check the ID and try again.`;
      throw channelId.startsWith('@') ? new InvalidHandleError(errorMessage) : new ChannelNotFoundError(errorMessage);
    }

    const channelDetails = response.data.items[0];

    // Fetch latest videos if channel ID is available
    if (channelDetails.id) {
      console.debug('Fetching channel videos:', { channelId: channelDetails.id });
      
      // Fetch all videos first
      const allVideosResponse = await getChannelVideos(channelDetails.id, { maxResults: 50 });
      
      if (allVideosResponse.videos.length > 0) {
        // Separate shorts and regular videos
        const shorts = allVideosResponse.videos.filter(video => video.isShort);
        const regularVideos = allVideosResponse.videos.filter(video => !video.isShort);

        console.debug('Video categorization:', {
          total: allVideosResponse.videos.length,
          shorts: shorts.length,
          regularVideos: regularVideos.length
        });

        channelDetails.latestVideo = regularVideos[0] || shorts[0];
        channelDetails.videos = [...regularVideos, ...shorts];
        channelDetails.nextPageToken = allVideosResponse.nextPageToken;
      }
    }

    return channelDetails;
  });

    return result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      const apiError = error.response?.data?.error;

      console.error('YouTube API Error:', { status, message: apiError?.message, details: apiError?.errors });

      switch (status) {
        case 400:
          throw new YouTubeAPIError('Invalid request. Please check the channel ID or handle format.');
        case 401:
          throw new YouTubeAPIError('Unable to authenticate with YouTube. Please try again later.');
        case 403:
          throw apiError?.errors?.some((e: any) => e.reason === 'quotaExceeded')
            ? new YouTubeAPIError('YouTube API quota exceeded. Please try again later.')
            : new YouTubeAPIError('Access denied. Please try again later.');
        case 404:
          const notFoundMessage = channelId.startsWith('@')
            ? `Channel "${channelId}" not found. Please check the handle and try again.`
            : `Channel ID "${channelId}" not found. Please check the ID and try again.`;
          throw channelId.startsWith('@')
            ? new InvalidHandleError(notFoundMessage)
            : new ChannelNotFoundError(notFoundMessage);
        case 429:
          throw new YouTubeAPIError('Too many requests. Please try again in a few minutes.');
        default:
          throw new YouTubeAPIError(
            apiError?.message || 'An unexpected error occurred while fetching channel details. Please try again later.'
          );
        }
      } else if (error instanceof InvalidHandleError || error instanceof ChannelNotFoundError || 
                (error && typeof error === 'object' && 'code' in error && error.code === 'QUOTA_EXCEEDED')) {
        throw error;
      } else {
        console.error('Network or Unexpected Error:', error);
        throw new YouTubeAPIError(
          'Unable to connect to YouTube services. Please check your internet connection and try again.'
        );
      }
    }
}

/**
 * Fetch the channel's videos from the uploads playlist.
 */
export async function getChannelVideos(
  channelId: string,
  options: VideoFetchOptions = {}
): Promise<{ videos: YouTubeVideo[]; nextPageToken?: string }> {
  const {
    includeShorts = false,
    maxResults = 50,
    pageToken,
  } = options;

  console.debug('getChannelVideos called:', { channelId, includeShorts, maxResults });
  console.debug('YouTube API Request:', {
    endpoint: 'videos',
    parts: ['statistics', 'snippet', 'contentDetails'],
    maxResults,
    pageToken
  });

  try {
    // Get latest uploads playlist ID
    const channelResponse = await axios.get(`${YOUTUBE_API_BASE}/channels`, {
      params: {
        part: 'contentDetails',
        id: channelId,
        key: YOUTUBE_API_KEY,
      },
    });

    const uploadsPlaylistId = channelResponse.data.items[0]?.contentDetails?.relatedPlaylists?.uploads;
    if (!uploadsPlaylistId) return { videos: [] };

    // Fetch playlist items with adjusted maxResults to account for filtering
    const adjustedMaxResults = includeShorts ? maxResults : maxResults * 2;

    const playlistResponse = await axios.get(`${YOUTUBE_API_BASE}/playlistItems`, {
      params: {
        part: 'snippet',
        playlistId: uploadsPlaylistId,
        maxResults: adjustedMaxResults,
        pageToken,
        key: YOUTUBE_API_KEY,
      },
    });

    if (!playlistResponse.data.items?.length) return { videos: [] };

    const videoIds = playlistResponse.data.items.map((item: any) => item.snippet.resourceId.videoId);

    // Get video statistics and content details for duration
    console.debug('Fetching video details:', { 
      videoIds: videoIds.length,
      includeShorts,
      maxResults 
    });

    const videoResponse = await axios.get(`${YOUTUBE_API_BASE}/videos`, {
      params: {
        part: 'statistics,snippet,contentDetails',
        id: videoIds.join(','),
        key: YOUTUBE_API_KEY,
      },
    });

    let videos = videoResponse.data.items.map((video: any) => {
      // Debug video metadata before processing
      console.debug('Processing video:', {
        id: video.id,
        title: video.snippet.title,
        duration: video.contentDetails?.duration,
        thumbnails: Object.keys(video.snippet.thumbnails || {})
      });

      const { viewCount, likeCount, commentCount } = video.statistics;
      const duration = video.contentDetails?.duration || '';
      const isShort = determineIfShort(video);

      // Skip if we're filtering by type
      if (options.includeShorts !== undefined && includeShorts !== isShort) {
        console.debug('Skipping video due to type mismatch:', {
          videoId: video.id,
          isShort,
          includeShorts,
          reason: isShort ? 'is a short' : 'not a short'
        });
        return null;
      }

      if (!viewCount || !likeCount || !commentCount) {
        console.warn('Invalid video data:', video);
      }

      return {
        id: video.id,
        title: video.snippet.title,
        description: video.snippet.description,
        publishedAt: video.snippet.publishedAt,
        thumbnail: video.snippet.thumbnails.high.url,
        viewCount: viewCount || '0',
        likeCount: likeCount || '0',
        commentCount: commentCount || '0',
        isShort,
      };
    }).filter(Boolean) as YouTubeVideo[];

    console.debug('Video filtering results:', {
      totalFetched: videoResponse.data.items.length,
      filteredVideos: videos.length,
      type: includeShorts ? 'Shorts' : 'Regular Videos'
    });

    // For the initial load, ensure we have exactly maxResults videos if available
    if (!pageToken) {
      videos = videos.slice(0, maxResults);
    }

    return {
      videos,
      nextPageToken: playlistResponse.data.nextPageToken,
    };
  } catch (error) {
    console.error('Error fetching channel videos:', error);
    return { videos: [] };
  }
}

/**
 * Parses an ISO 8601 duration (e.g., "PT1M30S") into total seconds.
 */
function parseDuration(duration: string): number {
  try {
    return duration
      .replace(/PT/, '')
      .replace(/H/, '*3600+')
      .replace(/M/, '*60+')
      .replace(/S/, '')
      .split('+')
      .reduce((sum: number, part: string) => {
        const [num, multiplier] = part.split('*').reverse();
        return sum + (parseInt(num, 10) || 0) * (parseInt(multiplier, 10) || 1);
      }, 0);
  } catch (error) {
    console.warn('Failed to parse duration:', duration);
    return 0;
  }
}

/**
 * Determines if a video is a YouTube Short using multiple signals
 */
function determineIfShort(video: any): boolean {
  const duration = parseDuration(video.contentDetails?.duration || '');
  
  // Check vertical ratio across all available thumbnail sizes
  const thumbnails = Object.entries(video.snippet?.thumbnails || {});
  const verticalThumbnails = thumbnails.filter(([size, thumb]: [string, any]) => 
    thumb?.height && thumb?.width && thumb.height > thumb.width
  ).length;
  
  // Consider it vertical if majority of thumbnails are vertical
  const isVertical = verticalThumbnails > thumbnails.length / 2;
  
  const hasShortTag = 
    video.snippet?.title?.toLowerCase().includes('#shorts') ||
    video.snippet?.description?.toLowerCase().includes('#shorts') ||
    video.snippet?.title?.toLowerCase().includes('short') ||
    video.snippet?.description?.toLowerCase().includes('short');
  
  console.debug('Short detection:', {
    videoId: video.id,
    title: video.snippet?.title,
    duration,
    isVertical,
    verticalThumbnails,
    totalThumbnails: thumbnails.length,
    hasShortTag,
    thumbnailAnalysis: thumbnails.map(([size, thumb]: [string, any]) => ({
      size,
      isVertical: thumb?.height > thumb?.width,
      dimensions: thumb ? `${thumb.width}x${thumb.height}` : 'N/A'
    })),
    contentDetails: video.contentDetails,
    shortSignals: {
      duration: duration > 0 && duration <= 60,
      vertical: isVertical,
      tags: hasShortTag
    }
  });
  
  // Determine if video is a Short based on multiple signals
  const isShort = (
    // Must be under 60 seconds
    duration > 0 && duration <= 60 &&
    // And either:
    (
      // Has vertical thumbnails
      isVertical ||
      // Or has Shorts tag
      hasShortTag ||
      // Or is in Shorts format (9:16)
      (video.snippet?.thumbnails?.maxres?.height || 0) / (video.snippet?.thumbnails?.maxres?.width || 1) > 1.5
    )
  );

  // Log final determination
  console.debug('Short classification:', {
    videoId: video.id,
    title: video.snippet?.title,
    isShort,
    reason: isShort ? [
      duration > 0 && duration <= 60 && 'duration',
      isVertical && 'vertical',
      hasShortTag && 'tags'
    ].filter(Boolean).join(', ') : 'not a short'
  });

  return isShort;
}

/**
 * Retrieves the comments for a given video ID.
 */
export async function getVideoComments(
  videoId: string,
  maxResults: number = 50
): Promise<YouTubeComment[]> {
  if (!YOUTUBE_API_KEY) {
    console.error('YouTube API Error: Missing API key');
    throw new YouTubeAPIError('Unable to access YouTube API. Please check your configuration.');
  }

  try {
    const response = await axios.get(`${YOUTUBE_API_BASE}/commentThreads`, {
      params: {
        part: 'snippet',
        videoId: videoId,
        maxResults,
        order: 'relevance',
        key: YOUTUBE_API_KEY,
      },
    });

    if (!response.data.items?.length) {
      return [];
    }

    return response.data.items.map((item: any) => ({
      id: item.id,
      authorDisplayName: item.snippet.topLevelComment.snippet.authorDisplayName,
      authorProfileImageUrl: item.snippet.topLevelComment.snippet.authorProfileImageUrl,
      authorChannelUrl: item.snippet.topLevelComment.snippet.authorChannelUrl,
      textDisplay: item.snippet.topLevelComment.snippet.textDisplay,
      likeCount: item.snippet.topLevelComment.snippet.likeCount,
      publishedAt: item.snippet.topLevelComment.snippet.publishedAt,
      updatedAt: item.snippet.topLevelComment.snippet.updatedAt,
      totalReplyCount: item.snippet.totalReplyCount,
    }));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      const apiError = error.response?.data?.error;

      console.error('YouTube API Error:', { status, message: apiError?.message });

      switch (status) {
        case 400:
          throw new YouTubeAPIError('Invalid video ID format.');
        case 403:
          if (apiError?.errors?.some((e: any) => e.reason === 'commentsDisabled')) {
            throw new YouTubeAPIError('Comments are disabled for this video.');
          } else if (apiError?.errors?.some((e: any) => e.reason === 'quotaExceeded')) {
            throw new YouTubeAPIError('YouTube API quota exceeded. Please try again later.');
          }
          throw new YouTubeAPIError('Unable to access video comments.');
        case 404:
          throw new YouTubeAPIError('Video not found or has been removed.');
        default:
          throw new YouTubeAPIError(apiError?.message || 'An error occurred while fetching comments.');
      }
    }
    throw new YouTubeAPIError(
      'Unable to load comments. Please check your internet connection and try again.'
    );
  }
}