import { useState, useEffect } from 'react';
import { URLInput } from './URLInput';
import { Sparkles } from 'lucide-react';
import { useLocation } from 'react-router-dom';

export function StickyURLInput() {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      // Get the hero section height (including some padding)
      const heroSection = document.querySelector('[data-section="hero"]');
      if (!heroSection) return;
      
      const heroHeight = heroSection.getBoundingClientRect().height;
      const scrollPosition = window.scrollY;
      
      setIsVisible(scrollPosition > heroHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-black to-black/95 border-t border-gray-800 transform transition-all duration-300 ease-in-out z-50 py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="max-w-md mx-auto">
          <div className="flex items-center gap-2 mb-2">
            <Sparkles className="h-4 w-4 text-purple-400" />
            <p className="text-sm text-gray-400">Reserve your Subscribr page URL:</p>
          </div>
          <URLInput onSubmit={(url) => {
            if (url) localStorage.setItem('pendingUrl', url);
          }} />
        </div>
      </div>
    </div>
  );
}