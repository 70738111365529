import { User } from 'firebase/auth';
import { QuotaManager } from './manager';
import type { RequestType, QuotaError } from './types';
import { QuotaStorage } from './storage';

async function logQuotaOperation(requestType: RequestType, userId: string, startTime: number) {
  const quotaManager = QuotaManager.getInstance();
  const cost = quotaManager.calculateCost(requestType);
  const elapsedMs = Date.now() - startTime;
  const quota = await QuotaStorage.getUserQuota(userId);

  console.debug('Quota Operation:', {
    requestType,
    userId,
    cost,
    elapsedMs,
    currentUsage: quota?.usage.dailyRequests || 0,
    timestamp: new Date().toISOString()
  });
}

export async function withQuota<T>(
  user: User,
  requestType: RequestType,
  operation: () => Promise<T>
): Promise<T> {
  const quotaManager = QuotaManager.getInstance();
  const startTime = Date.now();
  
  try {
    logQuotaOperation(requestType, user.uid, startTime);
    console.debug('Starting quota-protected operation:', { requestType });

    await quotaManager.checkQuota({
      user,
      requestType,
      timestamp: new Date()
    });
    
    console.debug('Quota check passed, executing operation');

    return await operation();
  } catch (error) {
    // Handle quota-specific errors
    if (error && typeof error === 'object' && 'code' in error && 
        (error as QuotaError).code === 'QUOTA_EXCEEDED') {
      console.debug('Quota Exceeded:', {
        requestType,
        error,
        elapsedMs: Date.now() - startTime
      });
      throw error;
    }
    
    // For other errors, wrap with more context
    console.error('Operation failed:', {
      requestType,
      error,
      elapsedMs: Date.now() - startTime
    });
    throw new Error(
      'Operation failed: ' + 
      (error instanceof Error ? error.message : 'Unknown error')
    );
  } finally {
    console.debug('Operation completed:', {
      requestType,
      elapsedMs: Date.now() - startTime
    });
  }
}