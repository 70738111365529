import { Users, ShoppingBag, BarChart3, HeartHandshake, Sparkles, TrendingUp, Mail, Target, LineChart, Gift, Building2, Globe2 } from 'lucide-react';
import { YouTubeIcon } from '../icons/YouTubeIcon';
import { URLInput } from '../forms/URLInput';
import { ChannelInput } from '../forms/ChannelInput';
import { ChannelPreview } from '../forms/ChannelPreview';
import { StickyURLInput } from '../forms/StickyURLInput';
import { Navbar } from '../navigation/Navbar';
import { HowItWorksSection } from '../sections/HowItWorksSection';
import { CRMPreviewSection } from '../sections/CRMPreviewSection';
import { CreatorOffers } from './CreatorOffers';
import { BrandAffinitySection } from '../analytics/BrandAffinitySection';
import { SimilarChannelsSection } from '../analytics/SimilarChannelsSection';
import { offers } from '../../data/mockData';
import { BrandReport } from '../reports/BrandReport';
import { SubscribrOffers } from './SubscribrOffers';
import { Youtube } from 'lucide-react';

const sampleSubscriber = {
  name: "Sarah Johnson",
  username: "sarahexplores",
  avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  email: "sarah@example.com",
  joinDate: "March 1, 2024",
  location: "San Francisco, CA",
  subscriptionTier: "premium" as const,
  engagementRate: 8.5,
  interests: ["Travel", "Photography", "Adventure", "Sustainable Tourism"],
  youtubeStats: {
    channelSubscribers: 156000,
    subscriptionDate: "June 2023",
    subscribrStats: {
      communityEngagement: {
        postsCount: 24,
        repliesCount: 86,
        lastActive: "2 hours ago",
        topThreads: 5
      },
      offers: {
        activeDeals: 3,
        totalSaved: 245,
        usedDeals: 8,
        lastPurchase: "March 15, 2024"
      },
      smartBenefits: {
        appliedDiscounts: 12,
        personalizedContent: 34,
        contentScore: 92
      }
    },
    subscriptions: {
      total: 142,
      similar: 28,
      categories: [
        { name: "Travel", count: 45 },
        { name: "Photography", count: 32 },
        { name: "Lifestyle", count: 25 }
      ]
    },
    engagement: {
      likedVideos: 89,
      playlists: 12,
      playlistDetails: [
        { title: "Travel Inspiration", visibility: "public", videoCount: 45 },
        { title: "Photography Tips", visibility: "public", videoCount: 28 },
        { title: "Gear Reviews", visibility: "unlisted", videoCount: 15 }
      ],
    },
    contentPreferences: [
      { category: "Travel Vlogs", percentage: 45 },
      { category: "Photography Tips", percentage: 30 },
      { category: "Gear Reviews", percentage: 25 }
    ]
  }
};

const features = [
  {
    name: 'Verified Subscriber CRM',
    description: 'Transform viewers into subscribers and build a verified email database with Google OAuth and YouTube API.',
    icon: Mail,
    color: 'from-blue-600 to-cyan-600',
  },
  {
    name: 'AI-Powered Insights',
    description: 'Gemini AI analyzes subscriber data to provide actionable insights and create compelling brand reports.',
    icon: Sparkles,
    color: 'from-indigo-600 to-violet-600',
  },
  {
    name: 'Rich Audience Insights',
    description: 'Merge Google Profiles and YouTube data to understand your audience and optimize partnerships.',
    icon: Target,
    color: 'from-purple-600 to-pink-600',
  },
  {
    name: 'Centralized Brand Deals',
    description: 'One destination for all your sponsorships with automated tracking and enhanced attribution.',
    icon: LineChart,
    color: 'from-green-600 to-emerald-600',
  }
];

const stats = [
  { 
    label: 'Total YouTube Creators',
    region: 'Global',
    value: '64M+',
    subtext: 'and growing daily'
  },
  {
    label: 'Global Market Size',
    region: '2023',
    value: '$127B+',
    subtext: 'Creator economy valuation'
  },
  {
    label: 'Projected Growth',
    region: '2030',
    value: '$528B+',
    subtext: '22.5% CAGR'
  },
  {
    label: 'Market Share',
    region: 'North America',
    value: '40%',
    subtext: 'Leading region globally'
  },
  { 
    label: 'Mid-Tier Creators',
    region: 'United States',
    value: '307K',
    subtext: '10K-50K subscribers'
  },
  { 
    label: 'Rising Creators',
    region: 'United States',
    value: '70K',
    subtext: '5K-10K subscribers'
  },
  { 
    label: 'Top Creators',
    region: 'United States',
    value: '8K',
    subtext: '1M+ subscribers'
  },
  { 
    label: 'Brand Deals',
    region: 'Sponsorships',
    value: '68%',
    subtext: 'Main income source for creators'
  },  
];

const testimonials = [
  {
    name: 'Alex Tech',
    handle: '@alextech',
    avatar: 'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    text: 'subscribr helped me 10x my revenue in just 3 months! The analytics are actually useful and my community loves the exclusive promo codes.',
    subscribers: '850K',
  },
  {
    name: 'Sarah Gaming',
    handle: '@sarahplays',
    avatar: 'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    text: 'Finally, a platform that gets creators! My merch store is popping off and the community features are next level.',
    subscribers: '1.2M',
  },
];

export function CreatorLanding() {
  return (
    <div className="bg-black text-white">
      <Navbar />

      <main>
        {/* Hero Section */}
        <div className="relative overflow-hidden bg-gradient-to-b from-gray-900 to-black" data-section="hero">
          <div className="absolute inset-0 bg-[url('https://img.freepik.com/free-photo/esport-online-game-streaming-asian-male-wear-headset-play-dogital-metaverse-cyber-space-online-match-compettition-sport-game-battle-night-timeyoung-asia-male-enjoy-exited-gamer-successful-celebration_609648-2624.jpg?t=st=1733392323~exp=1733395923~hmac=d858f47547eba1a0b79216d174d35ce1caf4e73fc255f8eb1725adc4071916ca&w=680')] opacity-10 bg-cover bg-center bg-no-repeat"></div>
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="text-center">
              <div className="flex items-center justify-center gap-2 text-purple-400 mb-6">
                <YouTubeIcon />
                <span className="text-sm font-medium">Built for YouTube Creators</span>
              </div>
              <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
                <span className="block">Turn your YouTube channel</span>
                <span className="block bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">into an online business</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-400 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                The CRM built for YouTube creators to unlock powerful subscriber insights, foster deeper connections with your fanbase, and make your audience irresistible to brands—powered by Google Profile data, YouTube subscriber data, and Gemini AI to centralize your brand deals and grow your creator business.
              </p>
              <div className="mt-8 max-w-md mx-auto space-y-4">
                <p className="text-sm text-gray-400 mb-2">Reserve your Subscribr page URL:</p>
                <URLInput onSubmit={(url) => {
                  if (url) localStorage.setItem('pendingUrl', url);
                }} />
                <p className="mt-3 text-sm text-gray-400">
                  <TrendingUp className="inline h-4 w-4 mr-1" />
                  Join today and start building your Subscribr community
                </p>
              </div>
            </div>
          </div>
        </div>
        <StickyURLInput />
        {/* Stats Section */}
        <div className="bg-gray-900 border-t border-gray-800">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-extrabold mb-4">YouTube Creator Economy</h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                From global reach to U.S. market breakdown
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {stats.map((stat) => (
                <div key={stat.label} className="bg-gray-800 p-6 rounded-lg text-center border border-gray-700 hover:border-purple-500 transition-all duration-200">
                  <p className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">
                    {stat.value}
                  </p>
                  <p className="text-xs text-purple-400 font-medium mt-1">{stat.region}</p>
                  <p className="mt-2 font-medium text-white">{stat.label}</p>
                  <p className="mt-1 text-xs text-gray-400">{stat.subtext}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Enhanced Channel Analysis Section */}
        <div className="relative bg-gray-900 border-t border-gray-800 overflow-hidden">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1492619375914-88005aa9e8fb?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80')] opacity-5 bg-cover bg-center"></div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="relative">
              <div className="text-center mb-12">
                <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-purple-900/30 border border-purple-700/30 mb-6">
                  <Youtube className="h-5 w-5 text-red-500" />
                  <span className="text-sm font-medium bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">
                    Channel Analytics
                  </span>
                </div>
                <h2 className="text-4xl font-extrabold bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text mb-4">
                  Discover Your Channel's Potential
                </h2>
                <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-400">
                  Enter your YouTube channel URL or @handle to unlock powerful insights and analytics
                </p>
              </div>
              <div className="max-w-3xl mx-auto">
                <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-2xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-6 sm:p-8">
                  <div className="flex items-center gap-3 mb-6">
                    <div className="p-3 rounded-lg bg-gradient-to-br from-purple-600 to-pink-600">
                      <Youtube className="h-5 w-5 text-white" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white">Channel Analysis</h3>
                      
                      <p className="text-sm text-gray-400">Powered by YouTube Data API v3</p>
                      
                    </div>
                  </div>
                  <ChannelInput onChannelFound={(channel) => console.log('Channel found:', channel)} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <HowItWorksSection />
      
        <CRMPreviewSection sampleSubscriber={sampleSubscriber} />

        <div className="bg-gray-900 border-t border-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="text-center mb-12">
              <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-purple-900/30 border border-purple-700/30 mb-6">
                <Building2 className="h-5 w-5 text-purple-400" />
                <span className="text-sm font-medium bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">
                  Market Overview
                </span>
              </div>
              <h2 className="text-3xl font-extrabold mb-4">Brand Partnership Insights</h2>
              <p className="text-gray-400 max-w-3xl mx-auto">
                Leverage AI-powered analytics to identify high-value brand partnerships and collaboration opportunities
                based on your verified subscriber data and content engagement patterns.
              </p>
            </div>
            

            
            {/* 
            <BrandAffinitySection />
 */}
            
            <div className="mt-12">
              <SimilarChannelsSection />
            </div>
          </div>
        </div>
        
        <div className="bg-gray-900 border-t border-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-extrabold mb-4">AI-Generated Brand Proposals</h2>
              <p className="text-gray-400 max-w-3xl mx-auto">
                Let our AI analyze your audience data and create compelling partnership proposals. Turn your subscriber insights into brand-ready presentations that highlight your unique value proposition.
              </p>
            </div>
          </div>
        </div>

        <BrandReport />
        
        <SubscribrOffers offers={offers} />
        
        <CreatorOffers />
        {/* Testimonials */}
        <div className="bg-gray-900 border-t border-gray-800 py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div>
              <h2 className="text-3xl font-extrabold text-center mb-12">
                Creators Love Us
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {testimonials.map((testimonial) => (
                  <div key={testimonial.handle} className="bg-gray-800 p-6 rounded-lg border border-gray-700">
                    <div className="flex items-center gap-4 mb-4">
                      <img
                        src={testimonial.avatar}
                        alt={testimonial.name}
                        className="h-12 w-12 rounded-full"
                      />
                      <div>
                        <h3 className="font-medium">{testimonial.name}</h3>
                        <p className="text-gray-400 text-sm flex items-center gap-2">
                          <YouTubeIcon className="h-4 w-4" />
                          {testimonial.subscribers} subscribers
                        </p>
                      </div>
                    </div>
                    <p className="text-gray-300">{testimonial.text}</p>
                  </div>
                ))}
              </div>
            </div>
         </div>
       </div>
        </main>
        
        {/* CTA Section */}
        <div className="bg-gradient-to-r from-purple-900 to-pink-900 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-extrabold mb-8">Ready to Transform Your Channel?</h2>
            <button className="bg-white text-gray-900 px-8 py-3 rounded-full font-medium hover:bg-gray-100 transition-all duration-200 hover:scale-105">
              Join the Waitlist
            </button>
            <p className="mt-4 text-sm text-gray-300">No credit card required. Limited spots available.</p>
          </div>
        </div>
      </div>
  );
}