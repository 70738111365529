import { useEffect, useState } from 'react';
import { checkServerStatus } from '../../lib/server';

interface ServerStatusProps {
  onConnectionError?: () => void;
}

export function ServerStatus({ onConnectionError }: ServerStatusProps) {
  const [status, setStatus] = useState({
    server: false,
    youtube: false,
    firebase: false,
    timestamp: ''
  });

  useEffect(() => {
    const verifyStatus = async () => {
      const result = await checkServerStatus();
      setStatus(result);
      
      if (!result.server) {
        onConnectionError?.();
      }
    };
    
    verifyStatus();
    
    // Set up periodic status checks
    const interval = setInterval(verifyStatus, 30000); // Check every 30 seconds
    
    return () => clearInterval(interval);
  }, []);

  return null; // Hidden component that just checks server status
}