import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { CreatorLanding } from './components/creator/CreatorLanding';
import { CreatorDashboard } from './components/dashboard/CreatorDashboard';
import { ConnectionError } from './components/error/ConnectionError';
import { ServerStatus } from './components/debug/ServerStatus';

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-gray-900 rounded-xl border border-gray-800 p-6 text-center">
        <h1 className="text-xl font-bold text-white mb-4">Something went wrong</h1>
        <pre className="text-sm text-red-400 bg-red-900/20 p-4 rounded-lg mb-4 overflow-auto">
          {error.message}
        </pre>
        <button
          onClick={resetErrorBoundary}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          Try again
        </button>
      </div>
    </div>
  );
}

function App() {
  const [hasConnectionError, setHasConnectionError] = useState(false);

  if (hasConnectionError) {
    return <ConnectionError onRetry={() => setHasConnectionError(false)} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <ServerStatus onConnectionError={() => setHasConnectionError(true)} />
        <Routes>
          <Route path="/" element={<CreatorLanding />} />
          <Route path="/:customUrl" element={<CreatorDashboard />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
