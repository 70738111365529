import { useState, useEffect } from 'react';
import { signInWithGoogle, checkRedirectResult } from '../lib/auth';
import { PageService } from '../lib/pages';
import { getChannelDetails } from '../lib/youtube';
import { auth } from '../lib/firebase';

interface UseURLReservationReturn {
  isLoading: boolean;
  error: string | null;
  success: string | null;
  showChannelInput: boolean;
  pendingUrl: string | null;
  handleSubmit: (url: string) => Promise<void>;
  handleChannelSubmit: (channelUrl: string) => Promise<void>;
}

export function useURLReservation(onSubmit?: (url: string) => void): UseURLReservationReturn {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [pendingUrl, setPendingUrl] = useState<string | null>(null);
  const [showChannelInput, setShowChannelInput] = useState(false);

  useEffect(() => {
    let isMounted = true;

    checkRedirectResult()
      .then(user => {
        if (!isMounted) return;

        const savedUrl = localStorage.getItem('pendingUrl');
        const urlToUse = (pendingUrl || savedUrl) ?? '';

        if (user && urlToUse) {
          setPendingUrl(urlToUse);
          setShowChannelInput(true);
          setSuccess(`URL "${urlToUse}" is available! Connect your YouTube channel to claim it.`);
          onSubmit?.(urlToUse);
          localStorage.removeItem('pendingUrl');
        } else if (!urlToUse && user) {
          setError('No URL was selected. Please try again.');
        }
      })
      .catch(error => {
        if (isMounted) {
          setError('Failed to process redirect. Please try again.');
        }
      });

    return () => {
      isMounted = false;
    };
  }, [pendingUrl, onSubmit]);

  const handleSubmit = async (customUrl: string) => {
    setError(null);
    setSuccess(null);
    setShowChannelInput(false); 

    try {
      setIsLoading(true);

      if (!customUrl) {
        throw new Error('Please enter a URL for your page.');
      }

      if (!PageService.validateCustomUrl(customUrl)) {
        throw new Error(
          'Invalid URL format. Use 3-30 characters, letters, numbers, hyphens, or underscores only.'
        );
      }

      const isAvailable = await PageService.isUrlAvailable(customUrl);

      if (!isAvailable) {
        throw new Error(`The URL "subscribr.xyz/${customUrl}" is already taken. Please choose another.`);
      }

      setSuccess(`The URL "subscribr.xyz/${customUrl}" is available!`);
      localStorage.setItem('pendingUrl', customUrl);

      const user = await signInWithGoogle();

      if (!user) {
        setSuccess('Please complete Google sign-in to continue...');
        return;
      }

      onSubmit?.(customUrl);
      setPendingUrl(customUrl);
      setShowChannelInput(true);
      setSuccess('URL reserved! Connect your YouTube channel to claim it.');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChannelSubmit = async (channelUrl: string) => {
    const urlToUse = (pendingUrl || localStorage.getItem('pendingUrl')) ?? '';

    if (!urlToUse) {
      setError('Please select a URL first');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let channelId = channelUrl.trim();
      if (!channelId.startsWith('@') && !channelId.startsWith('UC')) {
        channelId = `@${channelId}`;
      }

      const details = await getChannelDetails(channelId);
      const user = auth.currentUser;

      if (!user) {
        throw new Error('Please sign in to continue');
      }

      await PageService.reservePage(urlToUse, details.id, details, user.uid);
      setSuccess('Page successfully reserved! Redirecting to your new page...');

      // Redirect to the new dashboard page
      setTimeout(() => {
        window.location.href = `/${urlToUse}`;
      }, 1500);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to connect channel');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    success,
    showChannelInput,
    pendingUrl,
    handleSubmit,
    handleChannelSubmit
  };
}