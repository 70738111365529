import React from 'react';
import { memo } from 'react';
import { Loader2 } from 'lucide-react';
import type { YouTubeChannelDetails } from '../../types/youtube';
import { ChannelHeader } from './ChannelHeader';

interface DashboardHeaderProps {
  channelDetails: YouTubeChannelDetails | null;
}

export const DashboardHeader = memo(function DashboardHeader({ channelDetails }: DashboardHeaderProps) {
  // Debug channel details when they change
  console.debug('DashboardHeader: Rendering with channel details', {
    hasDetails: !!channelDetails,
    id: channelDetails?.id,
    title: channelDetails?.snippet?.title,
    totalVideos: channelDetails?.statistics?.videoCount,
    loadedVideos: channelDetails?.videos?.length || 0,
    shorts: channelDetails?.videos?.filter(v => v.isShort)?.length || 0
  });

  if (!channelDetails) {
    return (
      <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-4 sm:p-6 lg:p-8">
        <div className="flex items-center justify-center">
          <Loader2 className="h-6 w-6 animate-spin text-purple-500" />
        </div>
      </div>
    );
  }

  return (
    <ChannelHeader channelDetails={channelDetails} />
  );
});