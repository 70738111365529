import React from 'react';
import { memo } from 'react';
import { Youtube, Calendar, PlaySquare } from 'lucide-react';
import { ChannelMetrics } from './ChannelMetrics';
import type { YouTubeChannelDetails } from '../../types/youtube';

interface ChannelHeaderProps {
  channelDetails: YouTubeChannelDetails;
}

const formatDate = (dateString?: string): string => {
  if (!dateString) return 'Unknown';
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export const ChannelHeader = memo(function ChannelHeader({ channelDetails }: ChannelHeaderProps) {
  const { snippet } = channelDetails;

  return (
    <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-6 sm:p-8">
      {/* Channel Info */}
      <div className="flex flex-col sm:flex-row items-start gap-6 mb-8">
        <div className="flex items-center gap-4">
          <img
            src={snippet.thumbnails.medium.url}
            alt={snippet.title}
            className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-2 border-purple-500"
          />
          <div>
            <div className="flex items-center gap-2">
              <h1 className="text-2xl font-bold text-white">{snippet.title}</h1>
              <Youtube className="h-5 w-5 text-red-500" />
            </div>
            <p className="text-gray-400">{snippet.customUrl}</p>
            <p className="text-sm text-gray-500 mt-2 line-clamp-2">{snippet.description}</p>
            <div className="flex items-center gap-4 mt-3">
              <div className="flex items-center gap-2 text-sm text-gray-400">
                <Calendar className="h-4 w-4" />
                <span>Created {formatDate(snippet.publishedAt)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Metrics Grid */}
      <ChannelMetrics channelDetails={channelDetails} />
    </div>
  );
});