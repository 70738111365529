import {
  signInWithPopup, 
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  GoogleAuthProvider,
  type User
} from 'firebase/auth';
import type { FirebaseError } from 'firebase/app';
import { auth } from './firebase';

const AUTH_STATE = {
  REDIRECT_PENDING: 'auth_redirect_pending',
  PENDING_URL: 'pendingUrl',
  ACCESS_TOKEN: 'youtube_access_token'
};

const provider = new GoogleAuthProvider();

// Configure required scopes
provider.addScope('email');
provider.addScope('profile');
provider.addScope('https://www.googleapis.com/auth/youtube.readonly');
provider.addScope('https://www.googleapis.com/auth/youtube.force-ssl');

provider.setCustomParameters({
  prompt: 'select_account',
  access_type: 'offline',
  include_granted_scopes: 'true'
});

// Check for redirect result on page load
export async function checkRedirectResult(): Promise<User | null> {
  if (!localStorage.getItem(AUTH_STATE.REDIRECT_PENDING)) {
    return null;
  }

  console.log('checkRedirectResult: Initiating redirect result check.');
  try {
    const result = await getRedirectResult(auth);
    console.log('checkRedirectResult: getRedirectResult completed.', { result });

    // Clear pending state regardless of result
    localStorage.removeItem(AUTH_STATE.REDIRECT_PENDING);

    if (result) {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.accessToken) {
        console.log('checkRedirectResult: Access token obtained.', { accessToken: credential.accessToken });
      }
      return result.user;
    }

    console.warn('checkRedirectResult: No user found in redirect result.');
    return null;
  } catch (error) {
    console.error('checkRedirectResult: Redirect authentication error:', error);
    return null;
  }
}


export async function signInWithGoogle(): Promise<User | null> {
  try {
    console.log('signInWithGoogle: Attempting popup authentication.');
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('signInWithGoogle: Popup authentication successful.', { result });
      
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.accessToken) {
        localStorage.setItem(AUTH_STATE.ACCESS_TOKEN, credential.accessToken);
      }
      
      return result.user;
    } catch (error: any) {
      if (error.code === 'auth/popup-blocked') {
        console.log('signInWithGoogle: Popup blocked, falling back to redirect');
        localStorage.setItem(AUTH_STATE.REDIRECT_PENDING, 'true');
        await signInWithRedirect(auth, provider);
        return null;
      }
      throw error;
    }
  } catch (error) {
    console.error('signInWithGoogle: Authentication error encountered.', {
      error: error instanceof Error ? error.message : error,
    });
    throw error;
  }
}