import { GoogleGenerativeAI } from '@google/generative-ai';
import { formatMarkdown, formatSummarySection, formatVideoContext } from './markdown';
import { withQuota } from './quota/middleware';
import { auth } from './firebase';

const GEMINI_CONFIG = {
  apiKey: import.meta.env.VITE_GEMINI_API_KEY,
  model: {
    // name: "gemini-1.5-flash-002",         // gemini-1.5-flash-002 Model for Comment Analysis & Summarization
    name: 'gemini-2.0-flash-exp',        // gemini-2.0-flash-exp Model for Comment Analysis & Summarization
    temperature: 0.7,
    maxOutputTokens: 8192,
  }
};

interface VideoContext {
  title?: string;
  description?: string;
  publishedAt?: string;
  viewCount?: string;
  likeCount?: string;
}

export class AIService {
  private genAI: GoogleGenerativeAI;
  private model: any;

  constructor() {
    if (!GEMINI_CONFIG.apiKey) {
      throw new Error('GEMINI_API_KEY is not set in environment variables');
    }

    try {
      this.genAI = new GoogleGenerativeAI(GEMINI_CONFIG.apiKey);
      this.model = this.genAI.getGenerativeModel({
        model: GEMINI_CONFIG.model.name,
        generationConfig: {
          temperature: GEMINI_CONFIG.model.temperature,
          maxOutputTokens: GEMINI_CONFIG.model.maxOutputTokens,
        }
      });
    } catch (error) {
      console.error('Failed to initialize Gemini AI:', error);
      throw new Error('Failed to initialize AI model');
    }
  }

  async summarizeComments(context: string, videoContext?: VideoContext) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User must be authenticated to use AI features');
    }

    if (!context || typeof context !== 'string' || context.trim().length === 0) {
      throw new Error('Context must be a non-empty string');
    }
  
    return withQuota(user, 'aiSummary', async () => {
      const prompt = `
        ${videoContext ? formatVideoContext(videoContext) : ''}

        Analyze and summarize these YouTube comments. Focus on:
        ${formatSummarySection('Main Discussion Points', [
          'Key topics and themes discussed in the comments',
          'Most frequently mentioned aspects of the video',
          'Common questions, concerns, or confusion points',
          'Recurring topics or themes across multiple comments'
        ])}

        ${formatSummarySection('Audience Sentiment', [
          'Overall tone and emotional reactions',
          'Balance of positive vs negative feedback',
          'Level and quality of engagement',
          'Areas of consensus or disagreement'
        ])}

        ${formatSummarySection('Content Analysis', [
          'Relevance of comments to video topic',
          'Quality and depth of discussion',
          'Constructive feedback and suggestions',
          'Knowledge level demonstrated in comments'
        ])}

        ${formatSummarySection('Notable Insights', [
          'Unique perspectives and viewpoints',
          'Valuable suggestions for improvement',
          'Emerging patterns or trends',
          'Potential areas for follow-up content'
        ])}
        
        Comments to analyze:
        ${context}
        
        Please provide a well-structured markdown summary that:
        1. Uses proper markdown formatting (##, *, **, etc.)
        2. Organizes insights into clear sections
        3. Highlights key findings with bullet points
        4. Includes specific examples from comments
        5. Ends with actionable recommendations
      `;
  
      try {
        const result = await this.model.generateContent(prompt);
        const response = await result.response;
        let text = response.text();

        if (!text) {
          throw new Error('Failed to generate summary: Empty response from AI model');
        }
        
        // Format the markdown for better readability
        text = formatMarkdown(text);
  
        return text;
      } catch (error) {
        console.error('Summary generation error:', error);
        if (error instanceof Error) {
          throw new Error(`Failed to generate summary: ${error.message}`);
        }
        throw new Error('An unexpected error occurred while generating the summary');
      }
    });
  }
}