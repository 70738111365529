import { collection, doc, getDoc, setDoc, updateDoc, serverTimestamp, onSnapshot, runTransaction } from 'firebase/firestore';
import { db } from '../firebase';
import { DEFAULT_QUOTA_CONFIG } from './config';
import type { QuotaUsage } from './types';
 
const QUOTA_COLLECTION = 'quotas';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const quotaListeners = new Map<string, ((quota: QuotaUsage | null) => void)[]>();

export class QuotaStorage {
  private static cache = new Map<string, { data: QuotaUsage; timestamp: number }>();

  private static getCacheKey(userId: string): string {
    const today = new Date().toISOString().split('T')[0];
    return `${userId}_${today}`;
  }

  private static isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < CACHE_DURATION;
  }

  static async getUserQuota(userId: string): Promise<QuotaUsage | null> {
    const today = new Date().toISOString().split('T')[0];
    const quotaRef = doc(db, QUOTA_COLLECTION, `${userId}_${today}`);
    console.debug('QuotaStorage: Fetching quota', { userId, date: today });
    const cacheKey = this.getCacheKey(userId);
    
    // Check cache first
    const cached = this.cache.get(cacheKey);
    if (cached && this.isCacheValid(cached.timestamp)) {
      return cached.data;
    }
    
    try {
      const snapshot = await getDoc(quotaRef);
      if (!snapshot.exists()) {
        console.debug('QuotaStorage: No quota found');
        return null;
      }
      const data = snapshot.data();
      
      if (!data.lastUpdated) {
        console.error('QuotaStorage: Missing lastUpdated timestamp in quota');
        return null;
      }
      
      const quota = {
        ...data,
        lastUpdated: data.lastUpdated.toDate(),
        date: data.date || new Date().toISOString().split('T')[0]
      } as QuotaUsage;
      
      // Update cache
      this.cache.set(cacheKey, {
        data: quota,
        timestamp: Date.now()
      });
      
      console.debug('QuotaStorage: Retrieved quota', {
        usage: quota.usage,
        lastUpdated: quota.lastUpdated
      });
      
      return quota;
    } catch (error) {
      console.error('Error fetching quota:', { error, userId });
      return null;
    }
  }

  static async initializeQuota(userId: string): Promise<QuotaUsage> {
    const today = new Date().toISOString().split('T')[0];
    const quotaRef = doc(db, QUOTA_COLLECTION, `${userId}_${today}`);
    console.debug('QuotaStorage: Initializing quota', { userId, date: today });

    try {
      await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(quotaRef);
        if (!doc.exists()) {
          console.debug('QuotaStorage: Creating new quota document');
          transaction.set(quotaRef, {
            userId,
            date: today,
            lastUpdated: serverTimestamp(),
            usage: { dailyRequests: 0 }
          });
        }
      });
      
      // Fetch the initialized quota to get server timestamp
      const snapshot = await getDoc(quotaRef);
      const data = snapshot.data();
      
      if (!data?.lastUpdated) {
        console.error('QuotaStorage: Missing lastUpdated timestamp after initialization');
        throw new Error('Failed to initialize quota: Missing timestamp');
      }
      
      return {
        userId,
        date: today,
        usage: { dailyRequests: 0 },
        lastUpdated: data.lastUpdated.toDate()
      };
    } catch (error) {
      console.error('Error initializing quota:', error);
      throw new Error('Failed to initialize quota');
    }
  }

  static subscribeUserQuota(userId: string, callback: (quota: QuotaUsage | null) => void): () => void {
    const today = new Date().toISOString().split('T')[0];
    const quotaRef = doc(db, QUOTA_COLLECTION, `${userId}_${today}`);
    console.debug('QuotaStorage: Setting up quota subscription', { userId });

    // Clean up existing listener if any
    this.unsubscribeQuota(userId);

    const unsubscribe = onSnapshot(
      quotaRef, 
      (snapshot) => {
        if (!snapshot.exists()) {
          console.debug('QuotaStorage: No quota document in subscription');
          callback(null);
          return;
        }

        const data = snapshot.data();
         
         if (!data.lastUpdated) {
           console.error('QuotaStorage: Missing lastUpdated timestamp in subscription');
           callback(null);
           return;
         }
        const quotaData: QuotaUsage = {
          userId,
          date: data.date || today,
          usage: {
            dailyRequests: data.usage?.dailyRequests || 0,
          },
          lastUpdated: data.lastUpdated.toDate()
        };

        console.debug('QuotaStorage: Subscription update', {
          usage: quotaData.usage,
          lastUpdated: quotaData.lastUpdated
        });
        // Update cache with real-time data
        this.cache.set(this.getCacheKey(userId), {
          data: quotaData,
          timestamp: Date.now()
        });

        callback(quotaData);
      },
      (error) => {
        console.error('QuotaStorage: Error in quota subscription:', error);
        callback(null);
      }
    );

    return (): void => {
      unsubscribe();
      this.unsubscribeQuota(userId);
    };
  }

  static unsubscribeQuota(userId: string): void {
    quotaListeners.delete(userId);
    const cacheKey = this.getCacheKey(userId);
    this.cache.delete(cacheKey);
  }

  static async updateQuota(
    userId: string,
    updates: Partial<QuotaUsage['usage']>
  ): Promise<void> {
    const today = new Date().toISOString().split('T')[0];
    const quotaRef = doc(db, QUOTA_COLLECTION, `${userId}_${today}`);
    
    try {
      await runTransaction(db, async (transaction) => {
        const docSnapshot = await transaction.get(quotaRef);
        
        if (!docSnapshot.exists()) {
          transaction.set(quotaRef, {
            userId,
            date: today,
            lastUpdated: serverTimestamp(),
            usage: updates
          });
        } else {
          const currentData = docSnapshot.data();
          transaction.update(quotaRef, {
            usage: {
              ...currentData.usage,
              ...updates
            },
            lastUpdated: serverTimestamp()
          });
        }
      });
      
      // Force a refresh of the cache
      this.cache.delete(this.getCacheKey(userId));
    } catch (error) {
      console.error('Error updating quota:', error);
      throw new Error('Failed to update quota usage');
    }
  }

  static clearCache(): void {
    this.cache.clear();
  }
}