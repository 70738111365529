import type { FC } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { LoginButton } from './LoginButton';
import { UserMenu } from './UserMenu';

/**
 * AuthSection component handles the authentication state display in the navigation.
 * It shows either a loading spinner, login button, or user menu based on auth state.
 */
export const AuthSection: FC = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="w-8 h-8 rounded-full border-2 border-t-transparent border-purple-500 animate-spin" />
    );
  }

  return user ? <UserMenu user={user} /> : <LoginButton />;
};