import { memo } from 'react';
import { Users, BarChart3, TrendingUp, PlaySquare } from 'lucide-react';
import type { YouTubeChannelDetails } from '../../types/youtube';

interface ChannelMetricsProps {
  channelDetails: YouTubeChannelDetails;
}

const formatNumber = (num: string): string => {
  const n = parseInt(num, 10);
  if (isNaN(n)) return '0';
  if (n >= 1000000) return `${(n / 1000000).toFixed(1)}M`;
  if (n >= 1000) return `${(n / 1000).toFixed(1)}K`;
  return n.toString();
};

export const ChannelMetrics = memo(function ChannelMetrics({ channelDetails }: ChannelMetricsProps) {
  const { statistics, videos = [] } = channelDetails;
  
  // Calculate engagement rate from recent videos
  const recentVideos = videos.slice(0, 10);
  const avgEngagement = recentVideos.length > 0
    ? recentVideos.reduce((sum, video) => {
        const views = parseInt(video.viewCount) || 0;
        const likes = parseInt(video.likeCount) || 0;
        const comments = parseInt(video.commentCount) || 0;
        return sum + ((likes + comments) / views) * 100;
      }, 0) / recentVideos.length
    : 0;

  const metrics = [
    {
      label: 'Total Subscribers',
      value: formatNumber(statistics.subscriberCount),
      description: 'Verified subscribers',
      icon: Users,
      color: 'from-blue-600 to-cyan-600'
    },
    {
      label: 'Avg. Engagement',
      value: `${avgEngagement.toFixed(1)}%`,
      description: 'Last 10 videos',
      icon: TrendingUp,
      color: 'from-purple-600 to-pink-600'
    },
    {
      label: 'Total Videos',
      value: formatNumber(statistics.videoCount),
      description: 'Published content',
      icon: PlaySquare,
      color: 'from-orange-600 to-amber-600'
    },
    {
      label: 'Total Views',
      value: formatNumber(statistics.viewCount),
      description: 'Lifetime views',
      icon: BarChart3,
      color: 'from-green-600 to-emerald-600'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
      {metrics.map((metric) => (
        <div key={metric.label} className="bg-gray-800/50 rounded-xl p-6 border border-gray-700 hover:border-purple-500 transition-all duration-200">
          <div className="flex items-start justify-between mb-4">
            <div className={`p-3 rounded-lg bg-gradient-to-br ${metric.color}`}>
              <metric.icon className="h-5 w-5 text-white" />
            </div>
          </div>
          <h3 className="text-2xl font-bold text-white mb-1">{metric.value}</h3>
          <p className="text-lg text-gray-200 mb-2 font-medium">{metric.label}</p>
          <p className="text-sm text-gray-300">{metric.description}</p>
        </div>
      ))}
    </div>
  );
});