import type { EngagementMetric, Follower, Task } from '../types';

// Analytics Data
export const competitorAnalysis = [
  { name: 'Your Channel', subscribers: 156000, engagement: 8.5, brandDeals: 12 },
  { name: 'Similar Creator 1', subscribers: 142000, engagement: 4.2, brandDeals: 8 },
  { name: 'Similar Creator 2', subscribers: 168000, engagement: 3.8, brandDeals: 7 },
  { name: 'Similar Creator 3', subscribers: 134000, engagement: 5.1, brandDeals: 9 }
];

export const audienceOverlap = [
  { brand: 'Travel Gear', overlap: 78, potential: 92 },
  { brand: 'Photography', overlap: 65, potential: 85 },
  { brand: 'Lifestyle', overlap: 45, potential: 72 }
];

export const brandAffinityData = [
  { category: 'Luxury Travel', affinity: 85, conversion: 12.3 },
  { category: 'Camera Gear', affinity: 72, conversion: 8.7 },
  { category: 'Adventure Gear', affinity: 68, conversion: 7.9 }
];

export const partnershipTiers = [
  {
    name: 'Premium Integration',
    price: '$8,500',
    includes: [
      'Dedicated 15-20 minute video review',
      'Instagram Story series (5 stories)',
      'Community post with exclusive discount',
      '30-day link placement in video descriptions',
      'Dedicated email to subscriber list'
    ]
  },
  {
    name: 'Standard Package',
    price: '$5,500',
    includes: [
      '5-8 minute integrated review segment',
      'Instagram Story mention (3 stories)',
      'Community post with discount code',
      '14-day link placement in descriptions'
    ]
  }
];

export const pastDeals = [
  {
    brand: 'Away Luggage',
    deal: 'Premium Integration',
    results: {
      revenue: '$52,400',
      conversion: '9.2%',
      roi: '515%'
    },
    testimonial: "Heidi's audience alignment was incredible. The conversion rates exceeded our expectations by 3x the industry average.",
    contact: "Sarah Chen, Partnerships Lead"
  },
  {
    brand: 'DJI',
    deal: 'Standard Package',
    results: {
      revenue: '$38,900',
      conversion: '7.8%',
      roi: '480%'
    },
    testimonial: "The quality of traffic and engagement from Heidi's community was outstanding. We saw a significant boost in premium drone sales.",
    contact: "Michael Torres, Influencer Marketing"
  }
];

export const offers = [
  {
    id: '1',
    brand: 'Apple Inc.',
    brandLogo: 'https://images.unsplash.com/photo-1494698853255-d0fa521abc6c?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'MacBook Pro Creator Bundle',
    description: 'Save $200 on MacBook Pro + Final Cut Pro bundle for content creators',
    discount: '$200 OFF',
    code: 'CREATOR200',
    link: 'https://www.apple.com/macbook-pro',
    category: 'Tech',
    expiresAt: '2024-04-30',
    featured: true,
    stats: {
      uses: 312,
      revenue: 468000,
      conversion: 8.5
    }
  },
  {
    id: '2',
    brand: 'Disney',
    brandLogo: 'https://images.unsplash.com/photo-1612036782180-6f0b6cd846fe?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Disney+ Annual Plan',
    description: 'Get 25% off annual Disney+ subscription with exclusive creator discount',
    discount: '25% OFF',
    code: 'MAGIC25',
    link: 'https://www.disneyplus.com',
    category: 'Entertainment',
    expiresAt: '2024-04-15',
    featured: true,
    stats: {
      uses: 856,
      revenue: 34240,
      conversion: 7.8
    }
  },
  {
    id: '3',
    brand: 'PayPal',
    brandLogo: 'https://images.unsplash.com/photo-1559526324-593bc073d938?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Business Account Bonus',
    description: 'Get $100 bonus when you open a new PayPal Business account',
    discount: '$100 BONUS',
    code: 'BUSINESS100',
    link: 'https://www.paypal.com/business',
    category: 'Finance',
    expiresAt: '2024-05-15',
    featured: false,
    stats: {
      uses: 423,
      revenue: 42300,
      conversion: 7.2
    }
  },
  {
    id: '4',
    brand: 'Hulu',
    brandLogo: 'https://images.unsplash.com/photo-1522869635100-9f4c5e86aa37?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Streaming Bundle',
    description: 'Save 40% on Hulu + Live TV, Disney+, and ESPN+ bundle',
    discount: '40% OFF',
    code: 'STREAM40',
    link: 'https://www.hulu.com/live-tv',
    category: 'Entertainment',
    expiresAt: '2024-06-30',
    featured: true,
    stats: {
      uses: 678,
      revenue: 54240,
      conversion: 8.9
    }
  },
  {
    id: '5',
    brand: 'TurboTax',
    brandLogo: 'https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Self-Employed Edition',
    description: '20% off TurboTax Self-Employed + free consultation for creators',
    discount: '20% OFF',
    code: 'CREATOR20',
    link: 'https://turbotax.intuit.com/self-employed',
    category: 'Finance',
    expiresAt: '2024-04-15',
    featured: false,
    stats: {
      uses: 245,
      revenue: 24500,
      conversion: 6.5
    }
  },
  {
    id: '6',
    brand: 'Riot Games',
    brandLogo: 'https://images.unsplash.com/photo-1542751371-adc38448a05e?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'League of Legends Bundle',
    description: 'Exclusive creator bundle with rare skins and 2000 RP',
    discount: '35% OFF',
    code: 'LEGENDARY35',
    link: 'https://www.riotgames.com/en/league-of-legends',
    category: 'Gaming',
    expiresAt: '2024-05-30',
    featured: false,
    stats: {
      uses: 892,
      revenue: 44600,
      conversion: 9.2
    }
  },
  {
    id: '7',
    brand: 'Expedia',
    brandLogo: 'https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Travel Package Deal',
    description: '$200 off vacation packages over $1000 + VIP benefits',
    discount: '$200 OFF',
    code: 'VACATION200',
    link: 'https://www.expedia.com/packages',
    category: 'Travel',
    expiresAt: '2024-07-31',
    featured: true,
    stats: {
      uses: 534,
      revenue: 534000,
      conversion: 7.8
    }
  },
  {
    id: '8',
    brand: 'Geico',
    brandLogo: 'https://images.unsplash.com/photo-1504270997636-07ddfbd48945?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Auto Insurance',
    description: 'Save up to 15% on auto insurance + $50 sign-up bonus',
    discount: '15% + $50',
    code: 'DRIVE15',
    link: 'https://www.geico.com/auto-insurance',
    category: 'Insurance',
    expiresAt: '2024-06-15',
    featured: false,
    stats: {
      uses: 312,
      revenue: 93600,
      conversion: 5.8
    }
  },
  {
    id: '9',
    brand: 'Liberty Mutual',
    brandLogo: 'https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Home Insurance Bundle',
    description: 'Bundle home & auto insurance to save 20% + $100 bonus',
    discount: '20% + $100',
    code: 'BUNDLE20',
    link: 'https://www.libertymutual.com/bundle',
    category: 'Insurance',
    expiresAt: '2024-08-31',
    featured: false,
    stats: {
      uses: 267,
      revenue: 133500,
      conversion: 5.4
    }
  },
  {
    id: '10',
    brand: 'Procter & Gamble',
    brandLogo: 'https://images.unsplash.com/photo-1556228578-0d85b1a4d571?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Personal Care Bundle',
    description: '30% off P&G personal care products + free shipping',
    discount: '30% OFF',
    code: 'CARE30',
    link: 'https://us.pg.com/personal-care',
    category: 'Personal Care',
    expiresAt: '2024-05-31',
    featured: false,
    stats: {
      uses: 445,
      revenue: 22250,
      conversion: 6.7
    }
  }
];

export const metrics: EngagementMetric[] = [
  {
    platform: 'Total Followers',
    value: 124500,
    trend: 12.5,
    color: '#3B82F6',
  },
  {
    platform: 'Engagement Rate',
    value: 5200,
    trend: -2.3,
    color: '#10B981',
  },
  {
    platform: 'Content Reach',
    value: 89300,
    trend: 8.1,
    color: '#8B5CF6',
  },
  {
    platform: 'Revenue',
    value: 12400,
    trend: 15.2,
    color: '#F59E0B',
  },
];

export const followers: Follower[] = [
  {
    id: '1',
    name: 'Sarah Johnson',
    username: 'sarahcreates',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    platform: 'instagram',
    engagement: 8.5,
    lastInteraction: '2024-02-28',
    status: 'active',
  },
  {
    id: '2',
    name: 'Michael Chen',
    username: 'techmikey',
    avatar: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    platform: 'twitter',
    engagement: 6.2,
    lastInteraction: '2024-02-27',
    status: 'active',
  },
  {
    id: '3',
    name: 'Emma Wilson',
    username: 'emmacreative',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    platform: 'youtube',
    engagement: 12.8,
    lastInteraction: '2024-02-26',
    status: 'potential',
  },
];

export const tasks: Task[] = [
  {
    id: '1',
    title: 'Record YouTube video on productivity tips',
    dueDate: 'Tomorrow',
    priority: 'high',
    completed: false,
  },
  {
    id: '2',
    title: 'Schedule Instagram posts for the week',
    dueDate: 'Today',
    priority: 'medium',
    completed: true,
  },
  {
    id: '3',
    title: 'Respond to Twitter mentions',
    dueDate: 'Today',
    priority: 'low',
    completed: false,
  },
  {
    id: '4',
    title: 'Review analytics and adjust content strategy',
    dueDate: 'Next week',
    priority: 'high',
    completed: false,
  },
];