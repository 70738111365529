import React, { useEffect, useState } from 'react';
import { Sparkles, Target, BarChart3, TrendingUp, MessageCircle, Youtube } from 'lucide-react';
import type { YouTubeChannelDetails } from '../../types/youtube';
import { AIChannelAnalysis } from '../../lib/AIChannelAnalysis';

interface AnalysisState {
  insights: any;
  isLoading: boolean;
  error: string | null;
}

interface AIAnalysisProps {
  channelDetails: YouTubeChannelDetails;
}

export function AIAnalysis({ channelDetails }: AIAnalysisProps) {
  const [analysisState, setAnalysisState] = useState<AnalysisState>({
    insights: null,
    isLoading: false,
    error: null
  });
  const [hasAnalyzed, setHasAnalyzed] = useState(false);

  const analyzeChannel = async () => {
    console.debug('AI Analysis: Generate Analysis button clicked', {
      hasAnalyzed,
      isGeneratingSummary: isLoading,
      channelId: channelDetails?.id
    });

    try {
      setAnalysisState(prev => ({ ...prev, isLoading: true, error: null }));
      console.debug('AI Analysis: Starting channel analysis');
      
      const analyzer = new AIChannelAnalysis();
      const channelInsights = await analyzer.analyzeChannel(channelDetails);
      
      console.debug('AI Analysis: Analysis completed successfully');
      setAnalysisState(prev => ({ ...prev, insights: channelInsights }));
      setHasAnalyzed(true);
    } catch (err) {
      console.error('AI Analysis: Error analyzing channel:', err);
      console.error('Error analyzing channel:', err);
      setAnalysisState(prev => ({ 
        ...prev, 
        error: 'Failed to analyze channel data'
      }));
    } finally {
      setAnalysisState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const { insights, isLoading, error } = analysisState;

  if (!hasAnalyzed) {
    return (
      <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-4 sm:p-6 lg:p-8 mb-8">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 sm:p-3 rounded-lg bg-gradient-to-br from-purple-600 to-pink-600">
            <Sparkles className="h-4 w-4 sm:h-5 sm:w-5 text-white" />
          </div>
          <div>
            <h3 className="text-base sm:text-lg font-semibold text-white">AI Analysis</h3>
            <p className="text-sm text-gray-400">Powered by Gemini AI</p>
          </div>
        </div>
        
        <div className="bg-gray-900/30 rounded-lg p-8 border border-gray-700 text-center">
          <Sparkles className="h-12 w-12 text-purple-400 mx-auto mb-4" />
          <h4 className="text-lg font-medium text-white mb-2">Generate AI Analysis</h4>
          <p className="text-gray-400 mb-6 max-w-lg mx-auto">
            Get detailed insights about your content strategy, audience demographics, and growth opportunities using Gemini AI.
          </p>
          <button
            onClick={analyzeChannel}
            disabled={isLoading}
            className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg font-medium hover:opacity-90 transition-all duration-200 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <Sparkles className="h-5 w-5" />
                <span>Generate Analysis</span>
              </>
            )}
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-900/20 border border-red-800/30 text-red-400 p-6 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-4 sm:p-6 lg:p-8 mb-8">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 sm:p-3 rounded-lg bg-gradient-to-br from-purple-600 to-pink-600 relative">
          <Sparkles className="h-4 w-4 sm:h-5 sm:w-5 text-white" />
          {isLoading && (
            <div className="absolute inset-0 rounded-lg border-2 border-purple-400 border-t-transparent animate-spin" />
          )}
        </div>
        <div>
          <h3 className="text-base sm:text-lg font-semibold text-white">AI Analysis</h3>
          <p className="text-sm text-gray-400">Powered by Gemini AI</p>
        </div>
        <button
          onClick={analyzeChannel}
          disabled={isLoading}
          className="ml-auto flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg text-sm font-medium transition-colors disabled:opacity-50"
        >
          <Sparkles className="h-4 w-4" />
          <span>Refresh Analysis</span>
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Content Strategy */}
        <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-purple-900/30 rounded-lg">
              <Target className="h-5 w-5 text-purple-400" />
            </div>
            <h4 className="text-lg font-medium text-white">Content Strategy</h4>
          </div>
          <div className="space-y-4">
            <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
              <h5 className="text-sm font-medium text-purple-400 mb-2">Top Performing Topics</h5>
              <div className="space-y-2">
                {insights?.contentStrategy.topPerformingTopics.map((topic: any) => (
                  <div key={topic.topic} className="flex items-center justify-between">
                    <span className="text-sm text-gray-300">{topic.topic}</span>
                    <span className="text-sm text-green-400">{topic.engagement}% engagement</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-purple-900/20 p-4 rounded-lg border border-purple-800/30">
              <p className="text-sm text-gray-300 mb-2">
                Based on your recent performance, consider the following recommendations:
              </p>
              <ul className="list-disc list-inside space-y-2 text-sm text-gray-300">
                {insights?.contentStrategy.recommendations.map((rec: string, i: number) => (
                  <li key={i}>{rec}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Audience Insights */}
        <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-900/30 rounded-lg">
              <BarChart3 className="h-5 w-5 text-blue-400" />
            </div>
            <h4 className="text-lg font-medium text-white">Audience Insights</h4>
          </div>
          <div className="space-y-4">
            <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
              <h5 className="text-sm font-medium text-blue-400 mb-2">Demographics</h5>
              <div className="space-y-2">
                {insights?.audienceInsights.demographics.map((demo: any) => (
                  <div key={demo.category} className="flex items-center justify-between">
                    <span className="text-sm text-gray-300">{demo.category}</span>
                    <span className="text-sm text-blue-400">{demo.percentage}%</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-blue-900/20 p-4 rounded-lg border border-blue-800/30">
              <p className="text-sm text-gray-300">
                Your audience shows strong interest in {insights?.audienceInsights.interests.join(', ')}.
              </p>
            </div>
          </div>
        </div>

        {/* Growth Opportunities */}
        <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-green-900/30 rounded-lg">
              <TrendingUp className="h-5 w-5 text-green-400" />
            </div>
            <h4 className="text-lg font-medium text-white">Growth Opportunities</h4>
          </div>
          <div className="space-y-4">
            <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
              <h5 className="text-sm font-medium text-green-400 mb-2">Recommended Actions</h5>
              <ul className="space-y-2">
                {insights?.growthOpportunities.recommendedActions.map((action: string) => (
                  <li key={action} className="flex items-center gap-2 text-sm text-gray-300">
                    <span className="h-1.5 w-1.5 rounded-full bg-green-500" />
                    {action}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Engagement Analysis */}
        <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-pink-900/30 rounded-lg">
              <MessageCircle className="h-5 w-5 text-pink-400" />
            </div>
            <h4 className="text-lg font-medium text-white">Engagement Analysis</h4>
          </div>
          <div className="space-y-4">
            <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
              <h5 className="text-sm font-medium text-pink-400 mb-2">Community Engagement</h5>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-300">Comment Rate</span>
                  <span className="text-sm text-pink-400">{insights?.engagementMetrics.commentRate}%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-300">Like/View Ratio</span>
                  <span className="text-sm text-pink-400">{insights?.engagementMetrics.likeViewRatio}%</span>
                </div>
              </div>
            </div>
            <div className="bg-pink-900/20 p-4 rounded-lg border border-pink-800/30">
              <ul className="space-y-2">
                {insights?.engagementMetrics.trends.map((trend: string) => (
                  <li key={trend} className="flex items-center gap-2 text-sm text-gray-300">
                    <span className="h-1.5 w-1.5 rounded-full bg-pink-500" />
                    {trend}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}