import { useState, useEffect, useCallback } from 'react';
import { User } from 'firebase/auth';
import { QuotaStorage } from '../lib/quota/storage';
import { DEFAULT_QUOTA_CONFIG } from '../lib/quota/config';
import type { QuotaUsage, QuotaError } from '../lib/quota/types';

export interface QuotaStatus {
  dailyRequests: { used: number; total: number; percentage: number };
  lastUpdated?: Date;
  isLoading: boolean;
  error: string | null;
}

export function useQuotaStatus(user: User | null) {
  const [quotaStatus, setQuotaStatus] = useState<QuotaStatus>({
    dailyRequests: { used: 0, total: DEFAULT_QUOTA_CONFIG.limits.dailyRequests, percentage: 0 },
    lastUpdated: undefined,
    isLoading: true,
    error: null
  });

  const updateQuotaState = useCallback((quota: QuotaUsage | null): void => {
    if (!quota) {
      setQuotaStatus(prev => ({
        ...prev,
        ...prev,
        dailyRequests: {
          used: 0,
          total: DEFAULT_QUOTA_CONFIG.limits.dailyRequests,
          percentage: 0
        },
        isLoading: false,
        error: null
      }));
      return;
    }

    const used = quota.usage.dailyRequests;
    const total = DEFAULT_QUOTA_CONFIG.limits.dailyRequests;
    const percentage = total > 0 ? (used / total) * 100 : 0;

    setQuotaStatus({
      dailyRequests: {
        used,
        total,
        percentage: Math.min(100, Math.max(0, percentage))
      },
      lastUpdated: quota.lastUpdated,
      isLoading: false,
      error: null
    });
  }, []);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (!user) {
      setQuotaStatus(prev => ({
        ...prev,
        dailyRequests: {
          used: 0,
          total: DEFAULT_QUOTA_CONFIG.limits.dailyRequests,
          percentage: 0
        },
        isLoading: false,
        error: null
      }));
      return () => {};
    }

    // Set up real-time subscription
    unsubscribe = QuotaStorage.subscribeUserQuota(user.uid, updateQuotaState);

    return () => {
      unsubscribe?.();
    };
  }, [user, updateQuotaState]);

  return quotaStatus;
}