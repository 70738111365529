import React, { useState } from 'react';
import { Gauge, X, AlertCircle, RefreshCw, Info } from 'lucide-react';
import { useQuotaStatus } from '../../hooks/useQuotaStatus';
import { useAuth } from '../../hooks/useAuth';
import { auth } from '../../lib/firebase';

const formatTokenCost = (type: string): string => {
  switch (type) {
    case 'channelFetch': return '5 tokens';
    case 'videoFetch': return '10 tokens';
    case 'aiAnalysis':
    case 'aiSummary':
    case 'aiChannelAnalysis': return '15-20 tokens';
    default: return '5-10 tokens';
  }
};

export const QuotaStatus: React.FC = () => {
  const { user } = useAuth();
  const quotaStatus = useQuotaStatus(user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const loginTime = auth.currentUser?.metadata.lastSignInTime;
  const percentage = Math.round(Math.min(100, Math.max(0, quotaStatus.dailyRequests?.percentage || 0)));

  if (!user) return null;

  const formatTimeString = (date: Date | undefined) => {
    if (!date) return 'Unknown';
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC',
      hour12: false
    });
  };

  const getStatusColor = (percentage: number) => {
    if (percentage >= 90) return 'text-red-500';
    if (percentage >= 75) return 'text-orange-500';
    return percentage >= 50 ? 'text-yellow-500' : 'text-green-500';
  };

  const getProgressColor = (percentage: number) => {
    if (percentage >= 90) return 'bg-red-500';
    if (percentage >= 75) return 'bg-orange-500';
    return percentage >= 50 ? 'bg-yellow-500' : 'bg-green-500';
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center gap-2 px-3 py-1.5 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
      >
        <Gauge className={`h-4 w-4 ${getStatusColor(percentage)}`} />
        <span className="text-sm text-gray-300">
          {quotaStatus.isLoading ? (
            <RefreshCw className="h-4 w-4 animate-spin" />
          ) : (
            `${percentage}%`
          )}
        </span>
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
          <div className="bg-gray-900 rounded-xl border border-gray-800 shadow-xl w-full max-w-lg">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-6 border-b border-gray-800">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-gray-800 rounded-lg">
                  <Gauge className="h-5 w-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white">Token Balance</h3>
                  <p className="text-sm text-gray-400">Daily token limit and current usage</p>
                </div>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="p-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6 space-y-6">
              {quotaStatus.error ? (
                <div className="flex items-center gap-2 text-red-400 bg-red-900/20 p-4 rounded-lg border border-red-800/30">
                  <AlertCircle className="h-5 w-5 shrink-0" />
                  <p>{quotaStatus.error}</p>
                </div>
              ) : (
                <>
                  {/* Usage Progress */}
                  <div className="space-y-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-300">Daily Token Balance</span>
                        <div className="group relative">
                          <Info className="h-4 w-4 text-gray-500 cursor-help" />
                          <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-xs text-gray-300 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                            {quotaStatus.dailyRequests?.used || 0} / {quotaStatus.dailyRequests?.total || 0} tokens
                          </div>
                        </div>
                      </div>
                      <span className={`text-sm font-medium ${getStatusColor(percentage)}`}>
                        {percentage.toFixed(1)}%
                      </span>
                    </div>
                    <div className="h-2 bg-gray-800 rounded-full overflow-hidden">
                      <div
                        className={`h-full ${getProgressColor(percentage)} transition-all duration-300`}
                        style={{ width: `${percentage}%` }}
                      />
                    </div>
                  </div>

                  {/* Info Box */}
                  <div className="bg-purple-900/20 rounded-lg p-4 border border-purple-800/30">
                    <h4 className="text-sm font-medium text-purple-400 mb-2">About Token Usage</h4>
                    <div className="space-y-2 text-sm text-gray-300 mb-4">
                      <p>• Each operation costs a specific number of tokens:</p>
                      <p>• Channel analysis: {formatTokenCost('channelFetch')}</p>
                      <p>• Video analysis: {formatTokenCost('videoFetch')}</p>
                      <p>• AI operations: {formatTokenCost('aiAnalysis')}</p>
                      <p>• Token balance refreshes daily at midnight UTC</p>
                      <p>• Upgrade your plan for increased token limits</p>
                      <div className="mt-4">
                        <button
                          onClick={() => window.open('https://subscribr.xyz/pricing', '_blank')}
                          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg hover:opacity-90 transition-all duration-200"
                        >
                          <span>Buy More Tokens</span>
                          <span className="px-2 py-0.5 bg-white/20 rounded text-xs">
                            From $9.99/mo
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="mt-4 pt-4 border-t border-purple-800/30">
                      <h5 className="text-sm font-medium text-purple-400 mb-2">Debug Information</h5>
                      <div className="space-y-2 text-xs text-gray-400">
                        <p>• User ID: <span className="text-purple-400">{user.uid}</span></p>
                        <p>• Quota Document: <span className="text-purple-400">quotas/{user.uid}_{new Date().toISOString().split('T')[0]}</span></p>
                        <p>• Cache Status: <span className={quotaStatus.isLoading ? 'text-yellow-400' : 'text-green-400'}>{quotaStatus.isLoading ? 'Loading...' : 'Ready'}</span></p>
                        <p>• Current Token Usage: <span className="text-purple-400">{quotaStatus.dailyRequests.used} tokens</span></p>
                        <p>• Last Token Operation: <span className="text-purple-400">{quotaStatus.lastUpdated?.toISOString()}</span></p>
                        <p>• Last Operation Time (UTC): <span className="text-purple-400">{formatTimeString(quotaStatus.lastUpdated)} UTC</span></p>
                        <p>• Session Start (UTC): <span className="text-purple-400">{loginTime ? formatTimeString(new Date(loginTime)) : 'N/A'} UTC</span></p>
                        <p>• Current Time (UTC): <span className="text-purple-400">{formatTimeString(new Date())} UTC</span></p>
                      </div>
                    </div>
                  </div>

                  {/* Last Updated */}
                  {quotaStatus.lastUpdated && (
                    <div className="space-y-2 text-xs text-gray-500 mt-4">
                      <div className="flex items-center gap-1 text-purple-400">
                        <RefreshCw className="h-3 w-3 shrink-0" />
                        <span>Last token operation at {formatTimeString(quotaStatus.lastUpdated)} UTC</span>
                      </div>
                      {loginTime && (
                        <div className="flex items-center gap-1 text-blue-400">
                          <Info className="h-3 w-3 shrink-0" />
                          <span>Session started at {formatTimeString(new Date(loginTime))} UTC</span>
                        </div>
                      )}
                      <div className="flex items-center gap-1 text-green-400">
                        <Info className="h-3 w-3 shrink-0" />
                        <span>Current UTC time: {formatTimeString(new Date())} UTC</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {/* Modal Footer */}
            <div className="p-4 bg-gray-800/50 border-t border-gray-700">
              <div className="flex items-center justify-between">
                <span className="text-xs text-gray-400">Need more tokens?</span>
                <button
                  onClick={() => window.open('https://subscribr.xyz/pricing', '_blank')}
                  className="flex items-center gap-2 px-3 py-1.5 bg-purple-600 text-white text-sm rounded-lg hover:bg-purple-700 transition-colors"
                >
                  <span>View Pricing</span>
                  <span className="px-1.5 py-0.5 bg-white/20 rounded-md text-xs">Pro</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};