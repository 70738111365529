import React, { useState } from 'react';
import { Eye, ThumbsUp, MessageCircle, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';
import type { YouTubeChannelDetails, YouTubeVideo } from '../../types/youtube';
import { EngagementScoreOverlay } from '../video/EngagementScoreOverlay';

interface ChannelVideosProps {
  channelDetails: YouTubeChannelDetails;
  onSelectVideo: (video: YouTubeVideo) => void; // New prop to handle selection
}

export function ChannelVideos({ channelDetails, onSelectVideo }: ChannelVideosProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 6;
  
  // Filter out Shorts
  const regularVideos = channelDetails.videos?.filter(video => !video.isShort) || [];
  const totalPages = Math.ceil(regularVideos.length / videosPerPage);
  
  const currentVideos = regularVideos.slice(
    currentPage * videosPerPage,
    (currentPage + 1) * videosPerPage
  );

  const formatNumber = (num: string) => {
    return parseInt(num, 10).toLocaleString();
  };

  return (
    <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-4 sm:p-6 lg:p-8 mb-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h3 className="text-lg font-semibold text-white">Latest Videos</h3>
          <p className="text-sm text-gray-400">
            Showing {currentPage * videosPerPage + 1}-{Math.min((currentPage + 1) * videosPerPage, regularVideos.length)} of {regularVideos.length} videos
          </p>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
            className="p-2 text-gray-400 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(totalPages - 1, prev + 1))}
            disabled={currentPage >= totalPages - 1}
            className="p-2 text-gray-400 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentVideos.map((video) => (
          <div
            key={video.id}
            onClick={() => onSelectVideo(video)}
            className="cursor-pointer bg-gray-800/50 rounded-lg border border-gray-700 overflow-hidden hover:border-purple-500 transition-all duration-200"
          >
            <div className="relative aspect-video overflow-hidden">
              <EngagementScoreOverlay
                likeCount={video.likeCount}
                viewCount={video.viewCount}
                commentCount={video.commentCount}
                publishedAt={video.publishedAt}
                totalViews={channelDetails.statistics.viewCount}
                videos={channelDetails.statistics.videoCount}
                subscribers={channelDetails.statistics.subscriberCount}
                recentVideoViews={channelDetails.videos?.map(v => v.viewCount) || []}
              />
              <img
                src={video.thumbnail}
                alt={video.title}
                className="absolute inset-0 w-full h-full object-cover hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 p-4">
                <h5 className="text-white font-medium line-clamp-2 text-sm group-hover:text-purple-300 transition-colors duration-200">
                  {video.title}
                </h5>
              </div>
            </div>

            <div className="p-4">
              <div className="grid grid-cols-2 gap-4 text-center">
                <div className="flex items-center gap-2 justify-center">
                  <Eye className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-300">
                    {formatNumber(video.viewCount)}
                  </span>
                </div>
                <div className="flex items-center gap-2 justify-center">
                  <ThumbsUp className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-300">
                    {formatNumber(video.likeCount)}
                  </span>
                </div>
                <div className="flex items-center gap-2 justify-center">
                  <MessageCircle className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-300">
                    {formatNumber(video.commentCount)}
                  </span>
                </div>
                <div className="flex items-center gap-2 justify-center">
                  <Calendar className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-300">
                    {new Date(video.publishedAt).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}