import { getChannelDetails } from './youtube';
import { db } from './firebase';

const YOUTUBE_API_KEY = import.meta.env.VITE_YOUTUBE_API_KEY;

// Server status check
export async function checkServerStatus() {
  const status = {
    server: false,
    youtube: false,
    firebase: false,
    timestamp: new Date().toISOString()
  };

  try {
    // Server is running if we can execute this code
    status.server = true;
    console.debug('Server check successful:', status);
  } catch (error) {
    console.error('Server Error:', error);
  }

  try {
    if (!YOUTUBE_API_KEY) {
      throw new Error('YouTube API key not configured');
    }
    status.youtube = true;
    console.debug('YouTube API configured:', YOUTUBE_API_KEY ? 'Valid' : 'Missing');
  } catch (error) {
    console.error('YouTube API Error:', error);
  }

  try {
    // Firebase is initialized if we can access the db
    if (!db) throw new Error('Firebase not initialized');
    status.firebase = true;
    console.debug('Firebase configured:', status.firebase);
  } catch (error) {
    console.error('Firebase Error:', error);
  }

  return status;
}