import React, { useState, useEffect } from 'react';
import { Eye, ThumbsUp, MessageCircle, Calendar, Loader2, Sparkles } from 'lucide-react';
import type { YouTubeChannelDetails, YouTubeVideo, YouTubeComment } from '../../types/youtube';
import { getVideoComments } from '../../lib/youtube';
import { AIService } from '../../lib/gemini';
import { videoCache } from '../../lib/cache';
import { EngagementScoreOverlay } from './EngagementScoreOverlay';

interface SelectedVideoViewProps {
  video: YouTubeVideo;
  channelDetails: YouTubeChannelDetails;
}

export function SelectedVideoView({ video, channelDetails }: SelectedVideoViewProps) {
  const [comments, setComments] = useState<YouTubeComment[]>([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState<string | null>(null);
  const [summary, setSummary] = useState<string | null>(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [summaryError, setSummaryError] = useState<string | null>(null);

  useEffect(() => {
    // Reset state when video changes
    setComments([]);
    setSummary(null);
    setCommentsError(null);
    setSummaryError(null);
    
    // Check cache for comments
    const cachedComments = videoCache.getComments(channelDetails.id, video.id);
    if (cachedComments) {
      setComments(cachedComments);
      // Check for cached summary
      const cachedSummary = videoCache.getSummary(channelDetails.id, video.id);
      if (cachedSummary) {
        setSummary(cachedSummary);
      }
    }
  }, [video.id, channelDetails.id]);

  const handleLoadComments = async () => {
    if (isLoadingComments) return;
    
    setIsLoadingComments(true);
    setCommentsError(null);
    
    try {
      const videoComments = await getVideoComments(video.id);
      setComments(videoComments);
      
      // Cache the comments
      videoCache.setComments(channelDetails.id, video.id, videoComments);
    } catch (error) {
      setCommentsError(error instanceof Error ? error.message : 'Failed to load comments');
    } finally {
      setIsLoadingComments(false);
    }
  };

  const handleGenerateSummary = async () => {
    if (isGeneratingSummary || !comments.length) return;
    
    const videoContext = {
      title: video.title,
      description: video.description,
      publishedAt: video.publishedAt,
      viewCount: video.viewCount,
      likeCount: video.likeCount
    };
    
    setIsGeneratingSummary(true);
    setSummaryError(null);
    
    try {
      const aiService = new AIService();
      const commentsText = comments
        .map(c => `${c.authorDisplayName}: ${c.textDisplay}`)
        .join('\n\n');
      
      const summaryText = await aiService.summarizeComments(commentsText, videoContext);
      setSummary(summaryText);
      
      // Cache the summary
      videoCache.setSummary(channelDetails.id, video.id, summaryText);
    } catch (error) {
      setSummaryError(error instanceof Error ? error.message : 'Failed to generate summary');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const formatNumber = (num: string) => {
    return parseInt(num).toLocaleString();
  };

  return (
    <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-4 sm:p-6 lg:p-8 mb-8">
      <div className="relative aspect-video overflow-hidden mb-6">
        <EngagementScoreOverlay
          likeCount={video.likeCount}
          viewCount={video.viewCount}
          commentCount={video.commentCount}
          publishedAt={video.publishedAt}
          totalViews={channelDetails.statistics.viewCount}
          videos={channelDetails.statistics.videoCount}
          subscribers={channelDetails.statistics.subscriberCount}
          recentVideoViews={channelDetails.videos?.map(v => v.viewCount) || []}
        />
        <img
          src={video.thumbnail}
          alt={video.title}
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />
        <div className="absolute bottom-0 p-4">
          <h3 className="text-white font-medium text-lg line-clamp-2">
            {video.title}
          </h3>
        </div>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
        <div className="flex items-center gap-2 justify-center bg-gray-800/50 rounded-lg p-3">
          <Eye className="h-4 w-4 text-gray-400" />
          <span className="text-sm text-gray-300">
            {formatNumber(video.viewCount)}
          </span>
        </div>
        <div className="flex items-center gap-2 justify-center bg-gray-800/50 rounded-lg p-3">
          <ThumbsUp className="h-4 w-4 text-gray-400" />
          <span className="text-sm text-gray-300">
            {formatNumber(video.likeCount)}
          </span>
        </div>
        <div className="flex items-center gap-2 justify-center bg-gray-800/50 rounded-lg p-3">
          <MessageCircle className="h-4 w-4 text-gray-400" />
          <span className="text-sm text-gray-300">
            {formatNumber(video.commentCount)}
          </span>
        </div>
        <div className="flex items-center gap-2 justify-center bg-gray-800/50 rounded-lg p-3">
          <Calendar className="h-4 w-4 text-gray-400" />
          <span className="text-sm text-gray-300">
            {new Date(video.publishedAt).toLocaleDateString()}
          </span>
        </div>
      </div>

      {/* Comments Section */}
      <div className="mt-8">
        {!comments.length && !isLoadingComments && !commentsError && (
          <button
            onClick={handleLoadComments}
            className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gray-800 hover:bg-gray-700 text-gray-300 rounded-lg transition-colors duration-200"
          >
            <MessageCircle className="h-4 w-4" />
            <span>Load Top Comments</span>
          </button>
        )}
        
        {isLoadingComments && (
          <div className="flex items-center justify-center gap-2 text-gray-400 py-4">
            <Loader2 className="h-5 w-5 animate-spin" />
            <span>Loading comments...</span>
          </div>
        )}
        
        {commentsError && (
          <div className="text-red-400 text-sm text-center py-4">
            {commentsError}
          </div>
        )}
        
        {comments.length > 0 && (
          <div className="space-y-4">
            <div className="flex items-center justify-between mb-4">
              <h6 className="text-sm font-medium text-gray-400">Top Comments</h6>
              <span className="text-xs bg-gray-800 text-gray-400 px-2 py-1 rounded-full">
                {comments.length} comments
              </span>
            </div>
            <div className="space-y-6">
              {comments.map((comment) => (
                <div key={comment.id} className="bg-gray-900/30 rounded-lg p-4 border border-gray-800">
                  <div className="flex gap-3">
                    <img
                      src={comment.authorProfileImageUrl}
                      alt={comment.authorDisplayName}
                      className="w-8 h-8 rounded-full shrink-0"
                    />
                    <div className="flex-1 min-w-0">
                      <div className="flex flex-wrap items-center gap-2 mb-2">
                        <a
                          href={comment.authorChannelUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm font-medium text-gray-300 hover:text-white truncate max-w-[200px]"
                        >
                          {comment.authorDisplayName}
                        </a>
                        <span className="text-xs bg-gray-800/50 text-gray-400 px-2 py-0.5 rounded-full">
                          {new Date(comment.publishedAt).toLocaleDateString()}
                        </span>
                      </div>
                      <p className="text-sm text-gray-300 break-words">{comment.textDisplay}</p>
                      <div className="flex flex-wrap items-center gap-4 mt-3">
                        <span className="text-xs bg-gray-800/50 text-gray-400 px-2 py-1 rounded-full flex items-center gap-1">
                          <ThumbsUp className="h-3 w-3" />
                          {comment.likeCount}
                        </span>
                        {comment.totalReplyCount > 0 && (
                          <span className="text-xs bg-gray-800/50 text-gray-400 px-2 py-1 rounded-full flex items-center gap-1">
                            <MessageCircle className="h-3 w-3" />
                            {comment.totalReplyCount} replies
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Summary Section */}
            <div className="mt-8 pt-8 border-t border-gray-700">
              {!summary && !isGeneratingSummary && !summaryError && (
                <button
                  onClick={handleGenerateSummary}
                  className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-purple-600/20 hover:bg-purple-600/30 text-purple-300 rounded-lg transition-colors duration-200 hover:scale-[1.02]"
                >
                  <Sparkles className="h-4 w-4" />
                  <span>Generate AI Summary with Gemini</span>
                </button>
              )}
              
              {isGeneratingSummary && (
                <div className="flex items-center justify-center gap-2 text-purple-400 py-4">
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>Generating summary...</span>
                </div>
              )}
              
              {summaryError && (
                <div className="text-red-400 text-sm text-center py-4">
                  {summaryError}
                </div>
              )}
              
              {summary && (
                <div className="bg-purple-900/20 rounded-lg p-4 border border-purple-800/30 prose prose-invert max-w-none">
                  <div className="flex items-center gap-2 mb-3">
                    <Sparkles className="h-4 w-4 text-purple-400" />
                    <h6 className="text-sm font-medium text-purple-300">Gemini AI-Generated Summary</h6>
                  </div>
                  <div 
                    className="text-sm text-gray-300 markdown-content"
                    dangerouslySetInnerHTML={{ __html: summary }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}