import type { YouTubeChannelDetails } from '../../types/youtube';

interface CacheEntry<T> {
  data: T;
  timestamp: number;
  lastRefresh?: number;
}

const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
const REFRESH_INTERVAL = 15 * 60 * 1000; // 15 minutes

class ChannelCache {
  private static instance: ChannelCache;
  private cache: Map<string, CacheEntry<YouTubeChannelDetails>> = new Map();

  shouldRefresh(channelId: string): boolean {
    const entry = this.cache.get(channelId);
    if (!entry) return true;
    
    const now = Date.now();
    return !entry.lastRefresh || (now - entry.lastRefresh) > REFRESH_INTERVAL;
  }

  private constructor() {}

  static getInstance(): ChannelCache {
    if (!ChannelCache.instance) {
      ChannelCache.instance = new ChannelCache();
    }
    return ChannelCache.instance;
  }

  get(channelId: string): YouTubeChannelDetails | null {
    const entry = this.cache.get(channelId);
    if (!entry) return null;

    if (Date.now() - entry.timestamp > CACHE_DURATION) {
      this.cache.delete(channelId);
      return null;
    }

    return entry.data;
  }

  set(channelId: string, data: YouTubeChannelDetails): void {
    this.cache.set(channelId, {
      data,
      timestamp: Date.now(),
      lastRefresh: Date.now()
    });
  }

  updateRefreshTime(channelId: string): void {
    const entry = this.cache.get(channelId);
    if (entry) {
      entry.lastRefresh = Date.now();
      this.cache.set(channelId, entry);
    }
  }
  clear(): void {
    this.cache.clear();
  }
}

export const channelCache = ChannelCache.getInstance();