import { useState, useCallback, useEffect } from 'react';
import { Sparkles, Target, BarChart3, TrendingUp, MessageCircle, Youtube, Clock, Eye, ThumbsUp, Music, AlertCircle } from 'lucide-react';
import type { YouTubeChannelDetails } from '../../types/youtube';
import { AIChannelAnalysis, type ShortsInsights } from '../../lib/AIChannelAnalysis';
import { withQuota } from '../../lib/quota/middleware';
import { auth } from '../../lib/firebase';
import type { QuotaError } from '../../lib/quota/types';

// Known non-Shorts videos that should be excluded despite duration
const BLACKLISTED_VIDEO_IDS = [
  'MQ-khhKuUaU' // 21-second regular video that's not a Short
];

// Debug helper for video analysis
function debugVideoDetails(video: any) {
  console.debug('Video details:', {
    id: video.id,
    title: video.title,
    isShort: video.isShort,
    stats: {
      views: video.viewCount,
      likes: video.likeCount,
      comments: video.commentCount
    },
    metadata: {
      publishedAt: video.publishedAt,
      description: video.description?.includes('#shorts')
    }
  });
}

interface QuotaErrorState {
  message: string;
  resetTime?: Date;
}

interface AnalysisState {
  insights: ShortsInsights | null;
  isLoading: boolean;
  error: string | null;
  quotaError: QuotaErrorState | null;
}

interface ShortsAnalysisProps {
  channelDetails: YouTubeChannelDetails;
}

export function ShortsAnalysis({ channelDetails }: ShortsAnalysisProps) {
  const [analysisState, setAnalysisState] = useState<AnalysisState>({
    insights: null,
    isLoading: false,
    error: null,
    quotaError: null,
  });
  const [hasAnalyzed, setHasAnalyzed] = useState(false);

  // Debug channel details on mount and updates
  useEffect(() => {
    console.debug('ShortsAnalysis: Channel details received', {
      id: channelDetails.id,
      title: channelDetails.snippet.title,
      totalVideos: channelDetails.statistics.videoCount,
      loadedVideos: channelDetails.videos?.length || 0
    });

    // Debug first few videos
    channelDetails.videos?.slice(0, 3).forEach(debugVideoDetails);
  }, [channelDetails]);

  // Filter only Shorts from videos
  const shorts = channelDetails.videos?.filter(video => 
    video.isShort && !BLACKLISTED_VIDEO_IDS.includes(video.id)
  ) || [];

  // Debug shorts filtering results
  console.debug('ShortsAnalysis: Shorts filtering', { totalShorts: shorts.length });

  const analyzeShorts = useCallback(async () => {
    console.debug('Shorts Analysis: Analyze button clicked', {
      isAuthenticated: !!auth.currentUser,
      shortsCount: shorts.length
    });

    const user = auth.currentUser;
    if (!user) {
      console.debug('Shorts Analysis: User not authenticated');
      setAnalysisState(prev => ({
        ...prev,
        error: 'You must be logged in to analyze Shorts'
      }));
      return;
    }

    try {
      setAnalysisState(prev => ({ ...prev, isLoading: true, error: null }));
      
      console.debug('Shorts Analysis: Starting analysis with quota check');
      const result = await withQuota(user, 'shortsAnalysis', async () => {
        const analyzer = new AIChannelAnalysis();
        const insights = await analyzer.analyzeShortsContent({
          ...channelDetails,
          videos: shorts
        });
        console.debug('Shorts Analysis: Analysis completed successfully');
        return insights;
      });
      
      setAnalysisState(prev => ({ ...prev, insights: result }));
      setHasAnalyzed(true);
    } catch (err) {
      const quotaError = err as QuotaError;
      if (quotaError?.code === 'QUOTA_EXCEEDED') {
        console.debug('Shorts Analysis: Quota exceeded', {
          resetTime: quotaError.resetTime
        });
        setAnalysisState(prev => ({
          ...prev,
          quotaError: {
            message: 'Daily Shorts analysis quota reached. This quota is separate from your regular API quota.',
            resetTime: quotaError.resetTime
          }
        }));
      } else {
        console.error('Shorts Analysis: Error analyzing content:', err);
        setAnalysisState(prev => ({ 
          ...prev, 
          error: 'Failed to analyze shorts data'
        }));
      }
    } finally {
      setAnalysisState(prev => ({ ...prev, isLoading: false }));
    }
  }, [channelDetails, shorts]);

  const { insights, isLoading, error, quotaError } = analysisState;

  if (!hasAnalyzed) {
    return (
      <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 shadow-2xl backdrop-blur-sm p-4 sm:p-6 lg:p-8 mb-8">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 sm:p-3 rounded-lg bg-gradient-to-br from-red-600 to-pink-600">
            <Youtube className="h-4 w-4 sm:h-5 sm:w-5 text-white" />
          </div>
          <div>
            <h3 className="text-base sm:text-lg font-semibold text-white">Shorts Analysis</h3>
            <p className="text-sm text-gray-400">Powered by Gemini AI</p>
          </div>
        </div>
        
        <div className="bg-gray-900/30 rounded-lg p-8 border border-gray-700 text-center">
          <Youtube className="h-12 w-12 text-red-400 mx-auto mb-4" />
          <h4 className="text-lg font-medium text-white mb-2">Analyze Your Shorts</h4>
          <p className="text-gray-400 mb-6 max-w-lg mx-auto">
            Get detailed insights about your Shorts performance, audience engagement, and growth opportunities.
          </p>
          <button
            onClick={analyzeShorts}
            disabled={isLoading || shorts.length === 0}
            className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-red-600 to-pink-600 rounded-lg font-medium hover:opacity-90 transition-all duration-200 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <Sparkles className="h-5 w-5" />
                <span>{shorts.length === 0 ? 'No Shorts Found' : `Analyze ${shorts.length} Shorts`}</span>
              </>
            )}
          </button>
        </div>
      </div>
    );
  }

  if (quotaError) {
    return (
      <div className="bg-red-900/20 border border-red-800/30 rounded-lg p-6">
        <div className="flex items-start gap-3">
          <AlertCircle className="h-5 w-5 text-red-400 shrink-0 mt-0.5" />
          <div className="space-y-2">
            <p className="text-red-400 font-medium">{quotaError.message}</p>
            <p className="text-sm text-gray-400">
              Shorts analysis is currently unavailable. Quotas reset at {quotaError.resetTime?.toLocaleTimeString()} UTC.
            </p>
          </div>
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div className="bg-red-900/20 border border-red-800/30 text-red-400 p-6 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Shorts Overview */}
      {shorts.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {shorts.map((short) => (
            <div 
              key={short.id}
              className="bg-gray-800/50 rounded-lg border border-gray-700 overflow-hidden hover:border-red-500 transition-all duration-200"
            >
              <div className="relative aspect-[9/16] overflow-hidden">
                <img
                  src={short.thumbnail}
                  alt={short.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h5 className="text-white font-medium line-clamp-2 text-sm">
                    {short.title} <span className="text-xs text-red-400">#Shorts</span>
                  </h5>
                </div>
              </div>
              <div className="p-4 space-y-2">
                <div className="flex items-center gap-2 text-sm">
                  <Clock className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-300">
                    {new Date(short.publishedAt).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Eye className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-300">
                    {parseInt(short.viewCount).toLocaleString()} views
                  </span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <ThumbsUp className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-300">
                    {parseInt(short.likeCount).toLocaleString()} likes
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* AI Insights */}
      {insights && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Music Analysis */}
          <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-purple-900/30 rounded-lg">
                <Music className="h-5 w-5 text-purple-400" />
              </div>
              <h4 className="text-lg font-medium text-white">Music Analysis</h4>
            </div>
            <div className="space-y-4">
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
                <h5 className="text-sm font-medium text-purple-400 mb-2">Popular Tracks</h5>
                <div className="space-y-2">
                  {insights.musicAnalysis?.topTracks?.map((track) => (
                    <div key={track.name} className="bg-gray-900/50 p-3 rounded-lg">
                      <div className="flex items-center justify-between mb-1">
                        <span className="text-sm text-gray-300">{track.name}</span>
                        <span className="text-xs bg-purple-900/30 text-purple-400 px-2 py-0.5 rounded-full">
                          {track.popularity}% popular
                        </span>
                      </div>
                      {track.genre && (
                        <span className="text-xs text-gray-400">{track.genre}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="bg-purple-900/20 p-4 rounded-lg border border-purple-800/30">
                <h5 className="text-sm font-medium text-purple-400 mb-2">Music Trends</h5>
                <ul className="space-y-2">
                  {insights.musicAnalysis?.trends?.map((trend, index) => (
                    <li key={index} className="text-sm text-gray-300 flex items-center gap-2">
                      <span className="h-1.5 w-1.5 rounded-full bg-purple-500" />
                      {trend}
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
                <h5 className="text-sm font-medium text-purple-400 mb-2">Music Recommendations</h5>
                <ul className="space-y-2">
                  {insights.musicAnalysis?.recommendations?.map((rec, index) => (
                    <li key={index} className="text-sm text-gray-300 flex items-center gap-2">
                      <span className="h-1.5 w-1.5 rounded-full bg-purple-500" />
                      {rec}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Content Strategy */}
          <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-red-900/30 rounded-lg">
                <Target className="h-5 w-5 text-red-400" />
              </div>
              <h4 className="text-lg font-medium text-white">Shorts Strategy</h4>
            </div>
            <div className="space-y-4">
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
                <h5 className="text-sm font-medium text-red-400 mb-2">Top Performing Topics</h5>
                <div className="space-y-2">
                  {insights.contentStrategy?.topPerformingTopics?.map((topic, index) => (
                    <div key={topic.topic} className="bg-gray-900/50 p-4 rounded-lg border border-gray-800">
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-sm font-medium text-white">{topic.topic}</span>
                        <div className="flex items-center gap-2">
                          <span className="text-xs bg-green-900/30 text-green-400 px-2 py-0.5 rounded-full">
                            {topic.engagement}% engagement
                          </span>
                          <span className="text-xs bg-purple-900/30 text-purple-400 px-2 py-0.5 rounded-full">
                            {topic.trendStrength}% trending
                          </span>
                        </div>
                      </div>
                      
                      <p className="text-sm text-gray-400 mb-3">{topic.description}</p>
                      
                      <div className="flex flex-wrap gap-2 mb-3">
                        {topic.relatedHashtags.map((hashtag, idx) => (
                          <span
                            key={idx}
                            className="text-xs bg-blue-900/30 text-blue-400 px-2 py-0.5 rounded-full"
                          >
                            #{hashtag}
                          </span>
                        ))}
                      </div>
                      
                      <div className="flex items-center gap-2 text-xs text-gray-400">
                        <Clock className="h-3.5 w-3.5" />
                        <span>Best time to post: {topic.bestTimeToPost}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="bg-red-900/20 p-4 rounded-lg border border-red-800/30">
                <h5 className="text-sm font-medium text-red-400 mb-2">Strategic Recommendations</h5>
                <ul className="space-y-2">
                  {insights.contentStrategy?.recommendations?.map((rec, index) => (
                    <li key={index} className="text-sm text-gray-300 flex items-center gap-2">
                      <span className="h-1.5 w-1.5 rounded-full bg-red-500" />
                      {rec}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Performance Metrics */}
          <div className="bg-gray-900/30 rounded-lg p-6 border border-gray-700">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-pink-900/30 rounded-lg">
                <BarChart3 className="h-5 w-5 text-pink-400" />
              </div>
              <h4 className="text-lg font-medium text-white">Performance</h4>
            </div>
            <div className="space-y-4">
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
                <h5 className="text-sm font-medium text-pink-400 mb-2">Engagement Metrics</h5>
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-300">Average Views</span>
                    <span className="text-sm text-pink-400 flex items-center gap-1">
                      {shorts.length > 0 ? Math.round(shorts.reduce((sum, short) => sum + parseInt(short.viewCount || '0'), 0) / shorts.length).toLocaleString() : 'N/A'}
                      {insights.performanceMetrics?.viewsGrowth > 0 && (
                        <span className="text-xs text-green-400">
                          +{Number(insights.performanceMetrics.viewsGrowth).toFixed(1)}%
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-300">Average Likes</span>
                    <span className="text-sm text-pink-400 flex items-center gap-1">
                      {shorts.length > 0 ? Math.round(shorts.reduce((sum, short) => sum + parseInt(short.likeCount || '0'), 0) / shorts.length).toLocaleString() : 'N/A'}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-300">Engagement Rate</span>
                    <span className="text-sm text-pink-400">
                      {Number(insights.performanceMetrics?.engagementRate || 0).toFixed(1)}%
                    </span>
                  </div>
                </div>
              </div>
              {insights.performanceMetrics?.trends?.length > 0 && (
                <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700 mt-4">
                  <h5 className="text-sm font-medium text-pink-400 mb-2">Performance Trends</h5>
                  <ul className="space-y-2">
                    {insights.performanceMetrics?.trends?.map((trend, index) => (
                      <li key={index} className="text-sm text-gray-300 flex items-center gap-2">
                        <span className="h-1.5 w-1.5 rounded-full bg-pink-500" />
                        {trend}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};