import { DEFAULT_QUOTA_CONFIG } from './config';
import { QuotaStorage } from './storage';
import type { QuotaContext, QuotaError, RequestType } from './types';

export class QuotaManager {
  private static instance: QuotaManager;
  private quotaConfig = DEFAULT_QUOTA_CONFIG;

  private constructor() {
    console.debug('QuotaManager: Initialized with config', this.quotaConfig);
  }
  
  private validateRequestType(requestType: RequestType): void {
    if (!Object.prototype.hasOwnProperty.call(this.quotaConfig.costs, requestType)) {
      throw new Error(`Invalid request type: ${requestType}`);
    }
  }
  
  calculateCost(requestType: RequestType): number {
    this.validateRequestType(requestType);
    const cost = this.quotaConfig.costs[requestType];
    
    if (typeof cost !== 'number' || isNaN(cost) || cost < 0) {
      throw new Error(`Invalid cost value for request type: ${requestType}`);
    }
    
    console.debug('QuotaManager: Calculated cost', { 
      requestType, 
      cost,
      timestamp: new Date().toISOString()
    });
    
    return cost;
  }

  static getInstance(): QuotaManager {
    if (!QuotaManager.instance) {
      QuotaManager.instance = new QuotaManager();
    }
    return QuotaManager.instance;
  }

  async checkQuota(context: QuotaContext): Promise<void> {
    const { user, requestType } = context;
    
    console.debug('Checking quota:', {
      operation: 'checkQuota',
      userId: user.uid,
      requestType,
      cost: this.calculateCost(requestType),
      timestamp: new Date().toISOString()
    });

    // Get current quota usage
    let quota = await QuotaStorage.getUserQuota(user.uid);
    
    if (!quota) {
      console.debug('QuotaManager: Initializing new quota for user', user.uid);
      quota = await QuotaStorage.initializeQuota(user.uid);
      if (!quota) {
        throw new Error('Failed to initialize quota');
      }
    }
    
    const cost = this.calculateCost(requestType);
    const currentUsage = quota.usage.dailyRequests;
    const limit = this.quotaConfig.limits.dailyRequests;
    
    console.debug('QuotaManager: Current quota status', {
      requestType,
      cost,
      currentUsage,
      limit,
      wouldExceed: currentUsage + cost > limit,
      remainingQuota: limit - currentUsage,
      timestamp: new Date().toISOString()
    });

    // Check if quota would be exceeded
    if (currentUsage + cost > limit) {
      console.debug('Quota would be exceeded:', {
        operation: 'quotaExceeded',
        currentUsage,
        cost,
        limit,
        overage: currentUsage + cost - limit
      });
      throw this.createQuotaError(currentUsage, limit);
    }
    
    // Update quota usage
    await QuotaStorage.updateQuota(user.uid, {
      dailyRequests: currentUsage + cost
    });
    
    const remainingQuota = limit - (currentUsage + cost);

    console.debug('QuotaManager: Updated quota usage', {
      requestType,
      previousUsage: currentUsage,
      newUsage: currentUsage + cost,
      remainingQuota,
      timestamp: new Date().toISOString()
    });
  }
    
  private createQuotaError(current: number, limit: number): QuotaError {
    const resetTime = new Date();
    resetTime.setHours(24, 0, 0, 0); // Next day at midnight

    const message = `Daily API request limit reached (${current}/${limit}). Quota resets at ${resetTime.toLocaleTimeString()} UTC`;

    return {
      code: 'QUOTA_EXCEEDED',
      message,
      limit,
      current,
      resetTime
    };
  }
}