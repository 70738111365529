import { useURLReservation } from '../../hooks/useURLReservation';
import { Sparkles, Loader2, AlertCircle, Youtube, ArrowUpRight } from 'lucide-react';
import React from 'react';

interface URLInputProps {
  onSubmit?: (url: string) => void;
  className?: string;
}

export const URLInput: React.FC<URLInputProps> = ({ onSubmit, className }) => {
  const {
    isLoading,
    error,
    success,
    showChannelInput,
    handleSubmit,
    handleChannelSubmit
  } = useURLReservation(onSubmit);

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const input = form.elements.namedItem('url') as HTMLInputElement;
    const url = input.value.trim();
    
    if (!url) return;
    await handleSubmit(url);
  };

  const onChannelSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const input = form.elements.namedItem('channelUrl') as HTMLInputElement;
    const channelUrl = input.value.trim();
    
    if (!channelUrl) return;
    await handleChannelSubmit(channelUrl);
  };

  return (
    <div className={`space-y-4 ${className || ''}`}>
      <form onSubmit={onFormSubmit}>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3">
          <div className="relative flex-1">
            <div className="absolute inset-y-0 left-1 flex items-center pointer-events-none text-gray-400">
              <span className="whitespace-nowrap">subscribr.xyz/</span>
            </div>
            <input
              type="text"
              name="url"
              autoComplete="off"
              placeholder="your-username"
              className={`w-full pl-[105px] pr-4 py-3 bg-gray-800 border ${error ? 'border-red-500' : 'border-gray-700'} rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 ${error ? 'focus:ring-red-500' : 'focus:ring-purple-500'} focus:border-transparent min-h-[48px]`}
              disabled={isLoading}
            />
            {error && (
              <div className="absolute inset-y-0 right-3 flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            )}
          </div>
          <button 
            type="submit"
            disabled={isLoading}
            className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg font-medium hover:opacity-90 transition-all duration-200 hover:scale-105 min-h-[48px] disabled:opacity-50 disabled:hover:scale-100 shadow-lg whitespace-nowrap"
          >
            {isLoading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Checking...</span>
              </>
            ) : (
              <>
                <span>Reserve URL</span>
                <ArrowUpRight className="h-4 w-4" />
              </>
            )}
          </button>
        </div>
      </form>

      {/* Error Message */}
      {error && (
        <div className="flex items-center gap-2 bg-red-900/20 border border-red-800/30 text-red-400 px-4 py-3 rounded-lg">
          <AlertCircle className="h-5 w-5 shrink-0" />
          <p className="text-sm">{error}</p>
        </div>
      )}

      {/* Channel Input Form */}
      {showChannelInput && (
        <form onSubmit={onChannelSubmit} className="mt-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
              <Youtube className="h-5 w-5 text-red-500" />
            </div>
            <input
              type="text"
              name="channelUrl"
              autoComplete="off"
              placeholder="Enter channel URL or @handle"
              className="w-full pl-11 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              disabled={isLoading}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full mt-2 flex items-center justify-center gap-2 px-4 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Connecting...</span>
              </>
            ) : (
              <>
                <Youtube className="h-4 w-4" />
                <span>Connect YouTube Channel</span>
              </>
            )}
          </button>
        </form>
      )}

      {/* Success Message */}
      {success && (
        <div className="flex items-center gap-2 bg-green-900/20 border border-green-800/30 text-green-400 px-4 py-3 rounded-lg">
          <Sparkles className="h-5 w-5 shrink-0" />
          <p className="text-sm">{success}</p>
        </div>
      )}
    </div>
  );
};