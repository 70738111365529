import { SelectedVideoView as VideoView } from '../video/SelectedVideoView';
import type { YouTubeChannelDetails, YouTubeVideo } from '../../types/youtube';

interface SelectedVideoViewProps {
  video: YouTubeVideo;
  channelDetails: YouTubeChannelDetails;
}

// Re-export the video component for dashboard use
export function SelectedVideoView({ video, channelDetails }: SelectedVideoViewProps) {
  return <VideoView video={video} channelDetails={channelDetails} />;
}