import type { YouTubeChannelDetails } from '../types/youtube';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { withQuota } from './quota/middleware';
import { auth } from './firebase';

interface ChannelInsights {
  contentStrategy: {
    topPerformingTopics: Array<{
      topic: string;
      engagement: number;
    }>;
    recommendations: string[];
  };
  audienceInsights: {
    demographics: Array<{
      category: string;
      percentage: number;
    }>;
    interests: string[];
  };
  growthOpportunities: {
    recommendedActions: string[];
    potentialImpact: string;
  };
  engagementMetrics: {
    commentRate: number;
    likeViewRatio: number;
    trends: string[];
  };
}

export interface ShortsInsights {
  contentStrategy: {
    topPerformingTopics: Array<{
      topic: string;
      engagement: number;
      description: string;
      trendStrength: number;
      relatedHashtags: string[];
      bestTimeToPost: string;
    }>;
    recommendations: string[];
  };
  performanceMetrics: {
    viewsGrowth: number;
    engagementRate: number;
    trends: string[];
  };
  musicAnalysis: {
    topTracks: Array<{
      name: string;
      popularity: number;
      genre?: string;
    }>;
    recommendations: string[];
    trends: string[];
  };
}

const GEMINI_CONFIG = {
  apiKey: import.meta.env.VITE_GEMINI_API_KEY,
  // model: 'gemini-1.5-flash-002',        // gemini-1.5-flash-002 Model for all analysis
  model: 'gemini-2.0-flash-exp',        // gemini-2.0-flash-exp Model for all analysis
  shortsModel: 'gemini-exp-1206',   // gemini-exp-1206 Model for Shorts analysis
  temperature: 0.9,
  maxOutputTokens: 8192,            // Generous token limit
  retryConfig: {
    maxRetries: 3,
    initialDelay: 1000,
    maxDelay: 5000
  }
};

interface RetryOptions {
  maxRetries: number;
  initialDelay: number;
  maxDelay: number;
}

export class AIChannelAnalysis {
  private genAI: GoogleGenerativeAI;
  private retryConfig: RetryOptions;

  private async retry<T>(operation: () => Promise<T>): Promise<T> {
    let lastError: Error | null = null;
    
    for (let attempt = 0; attempt < this.retryConfig.maxRetries; attempt++) {
      try {
        return await operation();
      } catch (error) {
        lastError = error instanceof Error ? error : new Error('Unknown error');
        
        // Don't retry if it's not a 503 or overload error
        if (!lastError.message?.includes('503') && 
            !lastError.message?.toLowerCase().includes('overloaded')) {
          throw lastError;
        }
        
        const delay = Math.min(
          this.retryConfig.initialDelay * Math.pow(2, attempt),
          this.retryConfig.maxDelay
        );
        
        console.debug('AI Analysis: Retrying operation', {
          attempt: attempt + 1,
          delay,
          error: lastError.message
        });
        
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
    
    throw lastError || new Error('Maximum retry attempts reached');
  }

  constructor() {
    if (!GEMINI_CONFIG.apiKey) {
      throw new Error('GEMINI_API_KEY is not set in environment variables');
    }

    this.genAI = new GoogleGenerativeAI(GEMINI_CONFIG.apiKey);
    this.retryConfig = GEMINI_CONFIG.retryConfig;
  }

  /**
   * Analyzes YouTube Shorts content specifically
   */
  async analyzeShortsContent(channelDetails: YouTubeChannelDetails): Promise<ShortsInsights> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User must be authenticated to use AI features');
    }

    const shorts = channelDetails.videos?.filter(video => video.isShort) || [];
    if (shorts.length === 0) {
      throw new Error('No Shorts found in this channel');
    }

    try {
      return await withQuota(user, 'shortsAnalysis', async () => {
        const prompt = `
          Analyze these YouTube Shorts and provide strategic insights in JSON format.
          Focus on short-form vertical video content performance.
          
          For each top performing topic, analyze:
          1. Topic name and overall engagement rate
          2. Brief description of why this topic works well
          3. Trend strength (0-100 scale)
          4. Related hashtags that boost performance
          5. Best time to post based on engagement patterns
          
          Channel Information:
          -------------------
          Channel Title: ${channelDetails.snippet.title}
          Shorts Count: ${channelDetails.videos?.length || 0}
          
          Music Analysis:
          --------------
          Analyze music trends and patterns in video titles and descriptions for:
          1. Popular songs and artists mentioned
          2. Music genres and styles
          3. Trending audio patterns
          
          Shorts Performance:
          ------------------
          ${channelDetails.videos?.map(video => `
            Title: ${video.title}
            Description: ${video.description}
            Views: ${video.viewCount}
            Likes: ${video.likeCount}
            Comments: ${video.commentCount}
            Published: ${new Date(video.publishedAt).toLocaleDateString()}
          `).join('\n')}
          
          Return ONLY a valid JSON object with this exact structure:
          {
            "contentStrategy": {
              "topPerformingTopics": [
                {
                  "topic": string,
                  "engagement": number,
                  "description": string,
                  "trendStrength": number,
                  "relatedHashtags": string[],
                  "bestTimeToPost": string
                }
              ],
              "recommendations": string[]
            },
            "performanceMetrics": {
              "viewsGrowth": number,
              "engagementRate": number,
              "trends": string[]
            },
            "musicAnalysis": {
              "topTracks": [
                { "name": string, "popularity": number, "genre": string }
              ],
              "recommendations": string[],
              "trends": string[]
            }
          }

          IMPORTANT:
          - Use ONLY double quotes for property names and string values
          - Ensure all numbers are valid decimals (e.g., 8.5, not "8.5")
          - Do not include any text before or after the JSON object
          - Do not include any markdown code fences
        `;

        const model = this.genAI.getGenerativeModel({
          model: GEMINI_CONFIG.shortsModel,
          generationConfig: {
            temperature: 0.9,
            maxOutputTokens: 8192,
          }
        });

        try {
          const result = await model.generateContent(prompt);
          const response = await result.response;
          let text = response.text();
          
          // Clean up the response text
          text = text
            .trim()
            .replace(/```json\s*|\s*```/g, '')  // Remove code fences
            .replace(/^[\s\n]*{/, '{')          // Clean start
            .replace(/}[\s\n]*$/, '}');         // Clean end
          
          // Attempt to parse the cleaned JSON
          const insights: ShortsInsights = JSON.parse(text);
          
          // Validate insights structure
          if (!this.validateShortsInsights(insights)) {
            throw new Error('Invalid Shorts analysis response format');
          }
          
          // Ensure numeric values are valid
          insights.performanceMetrics.engagementRate = Number(insights.performanceMetrics.engagementRate) || 0;
          insights.performanceMetrics.viewsGrowth = Number(insights.performanceMetrics.viewsGrowth) || 0;
          insights.contentStrategy.topPerformingTopics = insights.contentStrategy.topPerformingTopics.map(topic => ({
            ...topic,
            engagement: Number(topic.engagement) || 0,
            trendStrength: Number(topic.trendStrength) || 0
          }));
          
          return insights;
        } catch (error) {
          console.error('Error analyzing Shorts:', error instanceof Error ? error.message : error);
          throw new Error('Failed to analyze Shorts content');
        }
      });
    } catch (error) {
      console.error('Error in analyzeShortsContent:', error);
      throw error;
    }
  }

  /**
   * Validates the structure of Shorts insights
   */
  private validateShortsInsights(insights: any): insights is ShortsInsights {
    return (
      insights &&
      Array.isArray(insights.contentStrategy?.topPerformingTopics) &&
      insights.contentStrategy.topPerformingTopics.every(topic => 
        typeof topic.topic === 'string' &&
        typeof topic.engagement === 'number' &&
        typeof topic.description === 'string' &&
        typeof topic.trendStrength === 'number' &&
        Array.isArray(topic.relatedHashtags) &&
        typeof topic.bestTimeToPost === 'string'
      ) &&
      Array.isArray(insights.contentStrategy?.recommendations) &&
      typeof insights.performanceMetrics?.viewsGrowth === 'number' &&
      typeof insights.performanceMetrics?.engagementRate === 'number' &&
      Array.isArray(insights.performanceMetrics?.trends) &&
      Array.isArray(insights.musicAnalysis?.topTracks) &&
      Array.isArray(insights.musicAnalysis?.recommendations) &&
      Array.isArray(insights.musicAnalysis?.trends)
    );
  }

  /**
   * Analyzes the channel using the Gemini model.
   */
  async analyzeChannel(channelDetails: YouTubeChannelDetails): Promise<ChannelInsights> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User must be authenticated to use AI features');
    }

    return withQuota(user, 'aiChannelAnalysis', async () => {
      const prompt = this.buildPrompt(channelDetails);
      const model = this.genAI.getGenerativeModel({
        model: GEMINI_CONFIG.model,
        generationConfig: {
          temperature: GEMINI_CONFIG.temperature,
          maxOutputTokens: GEMINI_CONFIG.maxOutputTokens,
        }
      });

      return this.callModel(model, prompt);
    });
  }

  /**
   * Sends a prompt to the model and returns validated insights.
   */
  private async callModel(model: any, prompt: string): Promise<ChannelInsights> {
    try {
      console.debug('AI Analysis: Calling model with prompt length:', prompt.length);

      const result = await this.retry(async () => {
        return await model.generateContent(prompt);
      });

      const response = await result.response;
      let text = response.text();
      
      console.debug('AI Analysis: Raw response:', {
        length: text.length,
        preview: text.slice(0, 200) + '...'
      });

      // Clean up the response text
      text = text
        .trim()
        .replace(/```json\s*/g, '')  // Remove opening code fence
        .replace(/\s*```/g, '')      // Remove closing code fence
        .replace(/^[\s\n]*{/, '{')   // Clean start
        .replace(/}[\s\n]*$/, '}');  // Clean end

      console.debug('AI Analysis: Cleaned response:', {
        length: text.length,
        preview: text.slice(0, 200) + '...'
      });

      const insights: ChannelInsights = JSON.parse(text);
      if (!this.validateInsights(insights)) {
        throw new Error('Invalid AI response format');
      }

      return insights;
    } catch (error) {
      console.error('AI Analysis: Model call error:', {
        error: error instanceof Error ? error.message : 'Unknown error',
        type: error instanceof Error ? error.constructor.name : typeof error
      });
      throw new Error(
        error instanceof Error 
          ? `Failed to analyze channel data: ${error.message}`
          : 'Failed to analyze channel data'
      );
    }
  }

  /**
   * Builds the prompt. If retrieval is included, instructs the model to find trending topics.
   * Also enforces grammatically correct, clear recommendations with separate sentences.
   */
  private buildPrompt(channelDetails: YouTubeChannelDetails): string {
    const instruction = `
      Analyze the channel's content and provide insights in valid JSON format.
      Focus on actionable metrics and trends.
      Ensure all numeric values are realistic and based on the provided data.
      Return ONLY a properly formatted JSON object matching the specified structure.
    `;

    const recentVideosSection = channelDetails.videos?.length ? `
      Recent Videos:
      -------------
      ${channelDetails.videos.slice(0, 50).map(video => `
        Title: ${video.title}
        Views: ${video.viewCount}
        Likes: ${video.likeCount}
        Comments: ${video.commentCount}
        Engagement Rate: ${((parseInt(video.likeCount) + parseInt(video.commentCount)) / parseInt(video.viewCount) * 100).toFixed(2)}%
        Published: ${new Date(video.publishedAt).toLocaleDateString()}
      `).join('\n')}
      
      Video Performance Summary:
      ------------------------
      Total Videos Analyzed: ${channelDetails.videos.length}
      Average Views: ${Math.round(channelDetails.videos.reduce((sum, v) => sum + parseInt(v.viewCount), 0) / channelDetails.videos.length).toLocaleString()}
      Average Likes: ${Math.round(channelDetails.videos.reduce((sum, v) => sum + parseInt(v.likeCount), 0) / channelDetails.videos.length).toLocaleString()}
      Average Comments: ${Math.round(channelDetails.videos.reduce((sum, v) => sum + parseInt(v.commentCount), 0) / channelDetails.videos.length).toLocaleString()}
    ` : '';

    // Instructions for grammatical correctness and separate ideas:
    const formattingInstructions = `
    - Each recommendation should be a separate, grammatically correct sentence.
    - Avoid run-on sentences and combine each distinct idea into its own bullet point or sentence.
    - Make sure the recommendations are actionable, clear, and easy to understand.
    `;

    return `
      Analyze this YouTube channel data and provide strategic insights.
      ${instruction}
      
      CRITICAL: The response MUST be valid JSON and match this exact structure:
      
      {
        "contentStrategy": {
          "topPerformingTopics": [
            { "topic": string, "engagement": number }
          ],
          "recommendations": string[]
        },
        "audienceInsights": {
          "demographics": [
            { "category": string, "percentage": number }
          ],
          "interests": string[]
        },
        "growthOpportunities": {
          "recommendedActions": string[],
          "potentialImpact": string
        },
        "engagementMetrics": {
          "commentRate": number,
          "likeViewRatio": number,
          "trends": string[]
        }
      }

      Channel Information:
      -------------------
      Channel Title: ${channelDetails.snippet.title}
      Subscribers: ${channelDetails.statistics.subscriberCount}
      Total Views: ${channelDetails.statistics.viewCount}
      Video Count: ${channelDetails.statistics.videoCount}
      Description: ${channelDetails.snippet.description}

      ${recentVideosSection}

      ${formattingInstructions}

      Ensure numeric values are realistic and based on provided data.
      Keep recommendations actionable and specific to the channel's content.
      ONLY return the JSON object. Do not include any text before or after the JSON.
    `;
  }


  private validateInsights(insights: any): insights is ChannelInsights {
    return (
      insights &&
      Array.isArray(insights.contentStrategy?.topPerformingTopics) &&
      Array.isArray(insights.contentStrategy?.recommendations) &&
      Array.isArray(insights.audienceInsights?.demographics) &&
      Array.isArray(insights.audienceInsights?.interests) &&
      Array.isArray(insights.growthOpportunities?.recommendedActions) &&
      typeof insights.growthOpportunities?.potentialImpact === 'string' &&
      typeof insights.engagementMetrics?.commentRate === 'number' &&
      typeof insights.engagementMetrics?.likeViewRatio === 'number' &&
      Array.isArray(insights.engagementMetrics?.trends)
    );
  }
}