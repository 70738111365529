import React, { FC, useMemo } from 'react';
import { ScoringService } from '../../lib/scoring';

interface EngagementScoreOverlayProps {
  likeCount: string;           // e.g., "8000000"
  viewCount: string;           // e.g., "238000000"
  commentCount: string;        // e.g., "10000"
  publishedAt?: string;        // Optional
  totalViews: string;          // Total views of the channel
  videos: string;              // Total number of videos on the channel
  subscribers: string;         // Total subscribers of the channel
  recentVideoViews: string[];  // Array of views from the 10 most recent videos
}

export const EngagementScoreOverlay: FC<EngagementScoreOverlayProps> = ({
  likeCount,
  viewCount,
  commentCount,
  publishedAt,
  totalViews,
  videos,
  subscribers,
  recentVideoViews
}) => {
  const { engagementScore, viewScore } = useMemo(() => {
    // Calculate Engagement Score
    const engagementScore = ScoringService.calculateEngagementScore({
      likeCount: likeCount || '0',
      viewCount: viewCount || '0',
      commentCount: commentCount || '0',
      publishedAt
    });

    // Calculate ChannelReach Score
    // Only use the first 10 non-shorts videos for consistent scoring
    const first10Views = recentVideoViews.slice(0, 10);
    const viewScore = ScoringService.calculateChannelReachScore({
      totalViews: totalViews || '0',
      videos: videos || '0',
      subscribers: subscribers || '0',
      recentVideoViews: first10Views
    });

    return { engagementScore, viewScore };
  }, [likeCount, viewCount, commentCount, publishedAt, totalViews, videos, subscribers, recentVideoViews]);

  // Determine colors based on scores
  const engagementScoreColor = ScoringService.getEngagementScoreColor(engagementScore);
  const reachScoreColor = getReachScoreColor(viewScore);

  return (
    <div className="absolute top-2 left-2 sm:top-3 sm:left-3 z-10 space-y-1.5 sm:space-y-2 max-w-[calc(100%-16px)] sm:max-w-[calc(100%-24px)]">
      {/* Engagement Score Badge */}
      <div className="flex flex-row items-center gap-1.5 sm:gap-2 bg-black/90 backdrop-blur-sm rounded-lg p-1.5 sm:p-2 border border-purple-500/30 w-fit">
        <div className={`h-7 sm:h-10 w-10 sm:w-14 rounded-lg bg-gradient-to-br ${engagementScoreColor} flex items-center justify-center shrink-0`}>
          <span className="text-[10px] sm:text-sm font-bold text-white tabular-nums">
            {engagementScore.toFixed(1)}%
          </span>
        </div>
        <div className="text-left min-w-0">
          <p className="text-[10px] sm:text-xs text-purple-300 font-medium whitespace-nowrap">
            Engagement Score
          </p>
          <p className="text-[8px] sm:text-[10px] text-gray-400 hidden xs:block truncate">
            Measures viewer interaction
          </p>
        </div>
      </div>

      {/* ViewScore Badge */}
      <div className="flex flex-row items-center gap-1.5 sm:gap-2 bg-black/90 backdrop-blur-sm rounded-lg p-1.5 sm:p-2 border border-purple-500/30 w-fit">
        <div className={`h-7 sm:h-10 w-12 sm:w-[4.5rem] rounded-lg bg-gradient-to-br ${reachScoreColor} flex items-center justify-center shrink-0`}>
          <span className="text-[9px] sm:text-[13px] font-bold text-white tabular-nums tracking-tighter">
            {viewScore.toFixed(1)}%
          </span>
        </div>
        <div className="text-left min-w-0">
          <p className="text-[10px] sm:text-xs text-purple-300 font-medium whitespace-nowrap">
            Channel Reach
          </p>
          <p className="text-[8px] sm:text-[10px] text-gray-400 hidden xs:block truncate">
            Recent video performance
          </p>
        </div>
      </div>
    </div>
  );
};

/**
 * Determines the color gradient based on the Channel Reach Score.
 */
const getReachScoreColor = (score: number): string => {
  if (score >= 70) return 'from-emerald-500 to-emerald-700';  // Excellent
  if (score >= 40) return 'from-blue-500 to-blue-700';       // Very Good
  if (score >= 20) return 'from-indigo-500 to-indigo-700';   // Good
  if (score >= 10) return 'from-amber-500 to-amber-700';      // Average
  return 'from-red-500 to-red-700';                          // Below Average
};