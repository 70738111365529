import React, { useState, useCallback, useMemo } from 'react';
import { MessageCircleHeart, ExternalLink, X, Menu, LogOut, User as UserIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { DashboardLink } from './DashboardLink';
import { UserMenu } from '../auth/UserMenu';
import { NavbarQuota } from './NavbarQuota';
import { useAuth } from '../../hooks/useAuth';
import { AuthSection } from '../auth/AuthSection';

export const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  const navigationLinks = useMemo(() => (
    <>
      <a href="#" className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
        For Creators
      </a>
      <a href="#" className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
        For Fans
      </a>
      <a href="#" className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
        For Brands
      </a>
    </>
  ), []);

  return (
    <header className="border-b border-gray-800">
      <nav className="relative z-50 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16">
        <div className="flex items-center justify-between h-full">
          {/* Logo */}
          <a href="/" className="flex items-center gap-2 hover:opacity-90 transition-opacity">
            <div className="h-8 w-8 rounded-lg bg-gradient-to-br from-purple-600 to-pink-600 flex items-center justify-center">
              <MessageCircleHeart className="h-5 w-5 text-white" />
            </div>
            <span className="font-bold text-xl">Subscribr</span>
          </a>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            {navigationLinks}
            {user && <NavbarQuota />}
            <DashboardLink />
            {isLoading ? (
              <div className="w-8 h-8 rounded-full border-2 border-t-transparent border-purple-500 animate-spin" />
            ) : user ? (
              <UserMenu user={user} />
            ) : (
              <AuthSection />
            )}
          </div>
          
          {/* Mobile menu button */}
          <button 
            onClick={toggleMenu}
            className="md:hidden p-2 rounded-lg hover:bg-gray-800 transition-colors"
          >
            {isMenuOpen ? (
              <X className="w-6 h-6 text-gray-400" />
            ) : (
              <Menu className="w-6 h-6 text-gray-400" />
            )}
          </button>
        </div>
        
        {/* Mobile Navigation */}
        <div className={`
          md:hidden fixed inset-x-0 top-16 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800 z-40
          transform transition-all duration-300 ease-in-out
          ${isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 pointer-events-none'}
        `}>
          <div className="px-4 py-6 space-y-4 relative">
            {navigationLinks}
            {user && (
              <div className="px-4 py-2">
                <NavbarQuota />
              </div>
            )}
            <DashboardLink />
            {!isLoading && (
              user ? (
                <div className="px-4">
                  <UserMenu user={user} />
                </div>
              ) : (
                <div className="px-4 py-2">
                  <AuthSection />
                </div>
              )
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}