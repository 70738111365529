import { LayoutDashboard } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { PageService } from '../../lib/pages';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function DashboardLink() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [customUrl, setCustomUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadUserPage = useCallback(async () => {
    if (!user) {
      setCustomUrl(null);
      return;
    }

    setIsLoading(true);
    try {
      const pages = await PageService.getPagesByUserId(user.uid);
      if (pages && pages.length > 0) {
        setCustomUrl(pages[0].customUrl);
      }
    } catch (error) {
      console.error('Error loading user page:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadUserPage();
  }, [loadUserPage]);

  if (!user || (!customUrl && !isLoading)) {
    return null;
  }

  return (
    <div className="flex items-center">
      <button
        onClick={() => customUrl && navigate(`/${customUrl}`)}
        disabled={isLoading}
        className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200 flex items-center gap-2"
      >
        <LayoutDashboard className="h-4 w-4" />
        <span>{isLoading ? 'Loading...' : 'Dashboard'}</span>
      </button>
    </div>
  );
}